import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';

import { Model } from '@nuov.io/model';
import { Page } from '@nuov.io/spring/build/esm/src/Page';

import { FilterSubject } from './filter-subject.model'

import { API_URL, HeaderLike, IRequestOptions } from './http.constants';

@Injectable()
export abstract class AbstractHttpService<T extends Model> {
  public item$: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);

  public numberOfItems$: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
   * 
   * @param params for generation colletion path
   */
  protected abstract getCollectionPath(params?: Params): string;


  /**
   * Constructor.
   *
   * @param http - Angular HttpClient class
   * @param apiUrl - apiUrl that will be injected in app main module
  */
  constructor(
    protected http: HttpClient,
    @Inject(API_URL) protected apiUrl: string
  ) {}

  /**
   * GET Collection request
   *
   * @param params for collection path
   *
   * @param queryParameters options of the request like headers, body, etc.
   *
   * @param headers
   */
  getCollection(
    params?: Params,
    filterParams?: HttpParams
  ): Observable<Page<T>> {
    const options = {
      params: filterParams
    };
    return this.http.get<Page<T>>(
      `${this.apiUrl}${this.getCollectionPath(params)}`,
      options
    ).pipe(
      tap(response => {
        this.numberOfItems$.next(response.totalElements);
      })
    );
  }

  /**
   * GET Item request
   * 
   * @param id item id
   *
   * @param params for collection path
   *
   * @param queryParameters options of the request like headers, body, etc.
   *
   * @param headers
   */
  getItem(
    id?: string,
    params?: any,
    queryParameters?: unknown[],
    headers?: HeaderLike[]
  ): Observable<T> {
    const queryParams = queryParameters ? [...queryParameters] : [];
    const options = {
      params: this.getParams(queryParams),
      headers: this.getHeaders(headers ?? []),
    };
    const apiUrl = id
      ? `${this.apiUrl}${this.getCollectionPath(params)}/${id}`
      : `${this.apiUrl}${this.getCollectionPath(params)}`;

    return this.http.get<T>(apiUrl, options).pipe(
      tap(response => {
        console.log(response)
        this.item$.next(response);
      })
    );
  }

  /**
   * POST request
   *
   * @param model body of the request.
   * 
   * @param params for collection path
   *
   * @param options options of the request like headers, body, etc.
   *
   */
  postItem(model: T, params?: any, options?: IRequestOptions): Observable<T> {
    return this.http.post<T>(
      `${this.apiUrl}${this.getCollectionPath(params)}`,
      model,
      options
    );
  }

  /**
   * PUT request
   *
   * @param id item id
   * 
   * @param model body of the request.
   *
   * @param params for collection path
   *
   * @param options options of the request like headers, body, etc.
   */
  putItem(id: string, model: T, params?: any, options?: IRequestOptions): Observable<T> {
    return this.http.put<T>(
      `${this.apiUrl}${this.getCollectionPath(params)}/${id}`,
      model,
      options
    );
  }

  /**
   * PATCH request
   *
   * @param id item id
   * 
   * @param model body of the request.
   *
   * @param params for collection path
   *
   * @param options options of the request like headers, body, etc.
   */
  patchItem(id: string, model: T, params?: any, options?: IRequestOptions): Observable<T> {
    return this.http.patch<T>(
      `${this.apiUrl}${this.getCollectionPath(params)}/${id}`,
      model,
      options
    );
  }

  /**
   * DELETE request
   *
   * @param id item id
   *
   * @param params for collection path
   * 
   * @param options options of the request like headers, body, etc.
   */
  deleteItem(id: string, params?: any, options?: IRequestOptions): Observable<T> {
    return this.http.delete<T>(
      `${this.apiUrl}${this.getCollectionPath(params)}/${id}`,
      options
    );
  }

  /**
   * Get parameters for http query options
   *
   * @param parameters a list of query parameters
   */
  private getParams(parameters: any[]): HttpParams {
    let params = new HttpParams();

    if (parameters) {
      parameters.forEach(param => {
        const paramKey = Object.keys(param)[0];
        const paramValue = param[paramKey];
        params = params.append(paramKey, paramValue);
      });
    }

    return params;
  }

  /**
   * Get headers
   *
   * @param headers
   */
  private getHeaders(headers: HeaderLike[]): HttpHeaders {
    let httpHeaders = new HttpHeaders();

    if (headers && headers.length) {
      headers.forEach(param => {
        const paramKey = Object.keys(param)[0];
        const paramValue = param[paramKey];
        if (paramValue) {
          httpHeaders = httpHeaders.append(paramKey, paramValue);
        }
      });
    }

    return httpHeaders;
  }
}
