import {AbstractCompositeUpdated} from '@nuov.io/model';
import {Image} from "./Image";
import {Inspection} from "./Inspection";
import {Manufacturer} from "./Manufacturer";
import {Product} from "./Product";
import {Production} from "./Production";
import {Unit} from "./Unit";

/** The Class UserUnitComposite. */
export class UserUnitComposite extends AbstractCompositeUpdated {

  /** The user id. */
  private readonly _userId: string | null;

  /** The manufacturer. */
  private readonly _manufacturer: Manufacturer;

  /** The product. */
  private readonly _product: Product;

  /** The production. */
  private readonly _production: Production;

  /** The image. */
  private readonly _image: Image;

  /** The unit. */
  private readonly _unit: Unit;

  /** The array of inspections. */
  private readonly _inspections: Inspection[];

  /**
   * Instantiates a new UserUnitComposite.
   *
   * @param id the id
   * @param manufacturer the manufacturer
   * @param product the product
   * @param production the production
   * @param image the image
   * @param unit the production
   * @param inspections the array of inspections
   * @param userId the user id
   * @param created the created date
   * @param updated the updated date
   * @param enabled the enabled flag
   */
  constructor(
      id: string,
      manufacturer:Manufacturer,
      product: Product,
      production: Production,
      image: Image,
      unit: Unit,
      inspections: Inspection[],
      userId?: string,
      created?: Date,
      updated?: Date,
      enabled?: boolean) {

    // parent
    super(id, created, updated, enabled);

    // manufacturer
    this._manufacturer = manufacturer;

    // product
    this._product = product;

    // production
    this._production = production;

    // image
    this._image = image;

    // unit
    this._unit = unit;

    // inspections
    this._inspections = inspections;

    // user id
    this._userId = userId !== undefined ? userId : null;
  }

  /**
   * Gets the user id.
   *
   * @return the user id
   */
  public get userId(): string | null {
    return this._userId;
  }

  /**
   * Gets the manufacturer.
   *
   * @return the manufacturer
   */
  public get manufacturer():Manufacturer {
    return this._manufacturer;
  }

  /**
   * Gets the product.
   *
   * @return the product
   */
  public  get product(): Product {
    return this._product;
  }

  /**
   * Gets the production.
   *
   * @return the production
   */
  public  get production(): Production {
    return this._production;
  }

  /**
   * Gets the image.
   *
   * @return the image
   */
  public get image(): Image  {
    return this._image;
  }

  /**
   * Gets the unit.
   *
   * @return the unit
   */
  public  get unit(): Unit {
    return this._unit;
  }

  /**
   * Gets the list of inspections.
   *
   * @return the list of inspections
   */
  public get inspections(): Inspection[] {
    return this._inspections;
  }
}
