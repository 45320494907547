import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Model } from '@nuov.io/model';

@Component({
  selector: 'io-provetech-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CustomSelectComponent,
    multi: true,
  }],
})
export class CustomSelectComponent implements ControlValueAccessor {
  @Input() options: any[] = [];// = ['ADMIN', 'READ', 'WRITE'];
  @Output() valueEmitter: EventEmitter<any> = new EventEmitter();
  selectedValue: any;
  onChange: any = () => {};
  onTouch: any = () => {};
  
  writeValue(value: any): void {
    this.selectedValue = value;    
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  onValueChange(value: any): void {
    this.selectedValue = value;
    this.onChange(value);
    this.onTouch();
    this.valueEmitter.emit(value);
  }
}
