export const environment = {
  keepAlive: true,
  httpApiURL: 'https://manufacturer.api.provenancetechnology.dev/v3',
  healthCheckURL: 'https://manufacturer.api.provenancetechnology.dev',
  bugsnag: {
    apiKey: 'd4a7babc930bc6a1b8bb62810f51cda0',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDUx2gEdZsUf8q6ho1jgF8eGg3TeWtsPRY',
    authDomain: 'provtech-dev--firebase--prods.firebaseapp.com',
    projectId: 'provtech-dev--firebase--prods',
    storageBucket: 'provtech-dev--firebase--prods.appspot.com',
    messagingSenderId: '95601030166',
    appId: '1:95601030166:web:9a8696ff6e714f12f07137',
  },
};
