import { Event } from './event.class';
import { filter, map } from 'rxjs/operators';
import { LoggingService } from "../logging/logging.service";
import { OperatorFunction, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

// See:
// https://levelup.gitconnected.com/communicate-between-angular-components-using-rxjs-7221e0468b2
// https://github.com/hoangtranson/sample-communication-subject
@Injectable({
  providedIn: 'root'
})
export class EventBusService {

  /** The subject. */
  private subject$ = new Subject();

  /**
   * Instantiates and EventBusService.
   */
  constructor(
    private loggingService: LoggingService
  ) {
    // log instantiation
    this.loggingService.informational(this.constructor.name + ' instantiated.');
  }

  /**
   * Publishes an event.
   *
   * @param event the event
   */
  publish(event: Event<any>) {
    this.loggingService.debug(this.constructor.name + '.publish(' + JSON.stringify(event.subject) + ')');
    this.subject$.next(event);
  }

  /**
   * Subscribes to an event.
   *
   * @param subject the subject
   * @param action the action
   */
  subscribe(subject: string, action: any): Subscription {
    this.loggingService.debug(this.constructor.name + '.subscribe(' + subject + ', ...)');
    return this.subject$.pipe<Event<any>, unknown>(
      filter<Event<any>>((event: Event<any>) => event.subject === subject) as OperatorFunction<unknown, Event<any>>,
      map<Event<any>, any>((event: Event<any>) => event["value"])).subscribe(action);
  }
}