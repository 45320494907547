import {Model} from "@nuov.io/model";
import {HttpParams} from "@angular/common/http";
import {FilterSubject} from "./filter-subject.model";
import {Input} from "@angular/core";

/**
 * The class AbstractFilter.
 *
 * @type-param T - The model type
 */
export abstract class AbstractFilter<T extends Model> {

  /** The http parameters. */
  private _httpParams: HttpParams = new HttpParams({});

  /** The change handler. */
  private _changeHandler!: () => void;

  /**
   * The constructor.
   */
  // constructor(protected filterSubject: FilterSubject<T>) {
  //   // this.filterSubject = filterSubject;
  //   console.log(this.filterSubject)
  //   this._changeHandler = this.filterSubject.changeHandler;
  //   this.filterSubject.addFilter(this);
  // }

  setFilterObserver(filterObserver: FilterSubject<T>) {    
    this._changeHandler = filterObserver.changeHandler;
    filterObserver.addFilter(this);
  }

  /**
   * Gets the http params.
   *
   * @returns the http params
   */
  public get httpParams(): HttpParams {
    return this._httpParams;
  }

  /**
   * Gets the change handler.
   *
   * @returns the change handler
   * @protected
   */
  protected get changeHandler(): () => void {
    return this._changeHandler;
  }

  // Andrii - we don't need this. We can access this through the getHttpParams()
  // public set httpParams(params: HttpParams) {
  //   this._httpParams = params;
  // }

  /**
   * Deletes a parameter.
   *
   * @param param the parameter key
   * @param value the parameter value
   * @protected
   */
  protected delete(param: string, value?: string | number | boolean): void {
    this._httpParams = this._httpParams.delete(param, value);
  }

  /**
   * Sets a parameter.
   *
   * @param param the parameter key
   * @param value the parameter value
   * @protected
   */
  protected append(param: string, value: string | number | boolean): void {
    this._httpParams = this._httpParams.set(param, value);
  }

  /**
   * Sets a group of parameters.
   *
   * @param params the parameter key
   * @protected
   */
  protected appendAll(params: { [params: string]: string | number | boolean }) {
    this._httpParams = this._httpParams.appendAll(params);
  }
}
