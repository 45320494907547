import { Observable } from 'rxjs';

import { Page } from '@nuov.io/spring/build/esm/src/Page';

import { AbstractHttpService } from '../http.service';
import {
  HeaderLike,
  HttpDeleteItem,
  HttpGetCollection,
  HttpGetItem,
  HttpPatchItem,
  HttpPostItem,
  IRequestOptions,
  MANUFACTURER_ID,
} from '../http.constants';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { UserAuthentication } from '@io-provetech/models'
import { Permission } from '@nuov.io/model';

export enum UserPermissionEnum {
  ADMIN = 'ADMIN',
  READ = 'READ',
  WRITE = 'WRITE'
}

/**
 * The class ManufacturerHttpService.
 */
@Injectable()
export class UserAuthenticationHttpService
  extends AbstractHttpService<UserAuthentication>
  implements
    HttpDeleteItem<UserAuthentication>,
    HttpGetCollection<UserAuthentication>,
    HttpGetItem<UserAuthentication>,
    HttpPatchItem<UserAuthentication>,
    HttpPostItem<UserAuthentication>
{
  getCollectionPath(params: Params): string {
    return `/manufacturers/${params[MANUFACTURER_ID]}/user-authentications`;
  }

  public override getCollection(params: Params, filterParams?: HttpParams): Observable<Page<UserAuthentication>> {
    return super.getCollection(params, filterParams);
  }

  public override getItem(id: string, params: Params, queryParameters?: unknown[], headers?: HeaderLike[]): Observable<UserAuthentication> {
    return super.getItem(id, params, queryParameters, headers);
  }

  public override postItem(
    model: UserAuthentication,
    params: Params,
    options?: IRequestOptions
  ): Observable<UserAuthentication> {
    return super.postItem(model, params, options);
  }

  public override patchItem(
    id: string,
    model: UserAuthentication,
    params: Params,
  ): Observable<UserAuthentication> {
    return super.patchItem(id, model, params);
  }

  public override deleteItem(id: string, params: Params): Observable<any> {
    return super.deleteItem(id, params);
  }

  /**
   * Calculates amount of each permission.
   * 
   * @param permissionsArray 
   * @returns 
   */
  public countPermissionsAmount(permissionsArray: Permission[]): { [key: string]: number } {
    const uniqueCount = {} as { [key: string]: number };

    for (const permission of permissionsArray) {
      if (uniqueCount[permission]) {
        uniqueCount[permission] += 1;
      } else {
        uniqueCount[permission] = 1;
      }
    }

    return uniqueCount;
  }
}
