import { Inject, Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import {
  HEALTH_CHECK_URL,
  defaultWakeUpCount,
  wakeUpInterval,
} from './polling.constants';

@Injectable()
export class PingService {
  constructor(
    private http: HttpClient,
    @Inject(HEALTH_CHECK_URL) private healthCheckURL: string
  ) {}

  /** Ping request to wake up server. */
  ping() {
    return this.http.get(this.healthCheckURL + '/ping').pipe(
      retry({
        count: defaultWakeUpCount,
        delay: wakeUpInterval,
        resetOnSuccess: false,
      }),
      catchError((error) => throwError(() => error))
    );
  }
}
