<div class="relative border border-gray-300 rounded-lg w-full h-full flex justify-center items-center">
  <label for="" class="absolute top-[-0.7rem] left-[10px] bg-white px-[3px] text-sm font-medium">Image</label>
  <input type='file' class="hidden" (change)="onFileChange($event)" #fileUpload>

  <div class="main flex flex-col justify-center items-center gap-y-1 pt-4 pb-2.5">
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 32L17.1716 22.8284C18.7337 21.2663 21.2663 21.2663 22.8284 22.8284L32 32M28 28L31.1716 24.8284C32.7337 23.2663 35.2663 23.2663 36.8284 24.8284L40 28M28 16H28.02M12 40H36C38.2091 40 40 38.2091 40 36V12C40 9.79086 38.2091 8 36 8H12C9.79086 8 8 9.79086 8 12V36C8 38.2091 9.79086 40 12 40Z" stroke="#D1D5DB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
      

    <button class="text-sm font-medium text-gray-900" (click)="fileUpload.click()">Upload file</button>

    <span class="text-xs font-medium text-gray-500">PNG, JPG, GIF up to 10MB</span>
      
  </div>
</div>



