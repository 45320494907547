/** The Enum Noun. */
export enum Noun {
  INSPECTION= "INS",
  MANUFACTURER = "PRC",
  NFC = "NFC",
  NFT = "NFT",
  PRODUCT = "PRD",
  PRODUCTION = "PRT",
  RFID = "RFD",
  UNIT = "UNT"
}
