<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <io-provetech-logo
      class="flex justify-center w-auto h-12"
    ></io-provetech-logo>
    <h2
      class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
    >
      Sign in to your account
    </h2>
  </div>
  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form class="space-y-6" [formGroup]="form">
        <div class="mt-1">
          <io-provetech-input
            [type]="'email'"
            label="Email"
            formControlName="email"
          >
            <p
              *ngIf="
                !form.controls['email'].valid &&
                (form.controls['email'].touched || form.controls['email'].dirty)
              "
            >
              {{ getError(form.controls['email']) }}
            </p>
          </io-provetech-input>
        </div>
        <div class="mt-1">
          <io-provetech-input
            [type]="'password'"
            label="Password"
            formControlName="password"
          >
            <p
              *ngIf="
                !form.controls['password'].valid &&
                (form.controls['password'].touched ||
                  form.controls['password'].dirty)
              "
            >
              {{ getError(form.controls['password']) }}
            </p>
          </io-provetech-input>
        </div>
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              type="checkbox"
              id="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
            />
            <label class="ml-2 block text-sm text-gray-900 cursor-pointer" for="checkbox">Remember me</label>
          </div>
          <div class="text-sm">
            <a class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
              >Forgot your password?</a
            >
          </div>
        </div>
        <div class="mt-1">
          <io-provetech-button label="Sign in" color="primary" [disabled]="!form.valid" (onClick)="onSubmit()"></io-provetech-button>
        </div>
      </form>
    </div>
  </div>
</div>
