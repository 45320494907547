<ng-container [ngSwitch]="icon">
  <div *ngSwitchCase="'product'" [innerHTML]="icons.productIcon | safe"></div>
  
  <div *ngSwitchCase="'production'" [innerHTML]="icons.productionIcon | safe"></div>

  <div *ngSwitchCase="'users'" [innerHTML]="icons.userAuthIcon | safe"></div>

  <div *ngSwitchCase="'address-book'" [innerHTML]="icons.addressBookIcon | safe"></div>

  <div *ngSwitchCase="'activity'" [innerHTML]="icons.activityIcon | safe"></div>

  <div *ngSwitchCase="'pencil'" [innerHTML]="icons.editIcon  | safe"></div>

  <div *ngSwitchCase="'xMark'" [innerHTML]="icons.xMark  | safe"></div>

  <div *ngSwitchCase="'check'" [innerHTML]="icons.checkIcon  | safe"></div>

  <div *ngSwitchCase="'filters'" [innerHTML]="icons.filtersIcon  | safe"></div>

  <div *ngSwitchCase="'add'" [innerHTML]="icons.addIcon  | safe"></div>

  <div *ngSwitchCase="'add-user'" [innerHTML]="icons.addUserIcon  | safe"></div>

  <div *ngSwitchCase="'trash'" [innerHTML]="icons.trashIcon  | safe"></div>

  <div *ngSwitchCase="'calendar'" [innerHTML]="icons.calendar  | safe"></div>
  
</ng-container>
  