import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { User } from '@io-provetech/models';
import { IDynamicFormModel } from '../dialog';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { IPhoneNumber } from '../_models/country-codes.data';


@Component({
  selector: 'io-provetech-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @Input() optionalFormConfig: IDynamicFormModel[] = [];

  form!: FormGroup;

  formConfig: IDynamicFormModel[] = [
    { id: 'email', value: '', validators: [Validators.email] }
  ];

  formFieldMap: { [key: string]: boolean } = {};

  @Output() formEmitter: EventEmitter<User> = new EventEmitter();

  constructor(
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  hasField(fieldId: string): boolean {
    return this.formFieldMap.hasOwnProperty(fieldId);
  }

  initForm(): void {
    this.form = this.fb.group({});

    this.formConfig = [...this.formConfig, ...this.optionalFormConfig];

    for (let field of this.formConfig) {
      const validators: ValidatorFn[] = field.validators ? field.validators : [];
      this.form.addControl(field.id, new FormControl(field.value, validators));
      this.formFieldMap[field.id] = true;
    }
  }

  onSubmit(): void {
    if (!this.form.valid) return;

    const phoneNumber = this.form.get('phoneNumber')?.value as IPhoneNumber;
    const phoneNumberUtil = new PhoneNumberUtil();
    const countryCode = phoneNumber.countryCode.code.toUpperCase();
    const parsedNumber = phoneNumberUtil.parseAndKeepRawInput(
      `${phoneNumber.countryCode.prefix} ${phoneNumber.phoneNumber}`,
      countryCode
    );
    const formattedNumber = phoneNumberUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);

    const formValue = {
      ...this.form.value,
      phoneNumber: formattedNumber
    }
    this.formEmitter.emit(formValue);
  }
}
