export const allCountries = [
  {
    name: 'Afghanistan (‫افغانستان‬‎)',
    prefix: '93',
    code: 'af',
  },
  {
    name: 'Albania (Shqipëri)',
    prefix: '355',
    code: 'al',
  },
  {
    name: 'Algeria (‫الجزائر‬‎)',
    prefix: '213',
    code: 'dz',
  },
  {
    name: 'American Samoa',
    prefix: '1',
    code: 'as',
  },
  {
    name: 'Andorra',
    prefix: '376',
    code: 'ad',
  },
  {
    name: 'Angola',
    prefix: '244',
    code: 'ao',
  },
  {
    name: 'Anguilla',
    prefix: '1',
    code: 'ai',
  },
  {
    name: 'Antigua and Barbuda',
    prefix: '1',
    code: 'ag',
  },
  {
    name: 'Argentina',
    prefix: '54',
    code: 'ar',
  },
  {
    name: 'Armenia (Հայաստան)',
    prefix: '374',
    code: 'am',
  },
  {
    name: 'Aruba',
    prefix: '297',
    code: 'aw',
  },
  {
    name: 'Ascension Island',
    prefix: '247',
    code: 'ac',
  },
  {
    name: 'Australia',
    prefix: '61',
    code: 'au',
  },
  {
    name: 'Austria (Österreich)',
    prefix: '43',
    code: 'at',
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    prefix: '994',
    code: 'az',
  },
  {
    name: 'Bahamas',
    prefix: '1',
    code: 'bs',
  },
  {
    name: 'Bahrain (‫البحرين‬‎)',
    prefix: '973',
    code: 'bh',
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    prefix: '880',
    code: 'bd',
  },
  {
    name: 'Barbados',
    prefix: '1',
    code: 'bb',
  },
  {
    name: 'Belarus (Беларусь)',
    prefix: '375',
    code: 'by',
  },
  {
    name: 'Belgium (België)',
    prefix: '32',
    code: 'be',
  },
  {
    name: 'Belize',
    prefix: '501',
    code: 'bz',
  },
  {
    name: 'Benin (Bénin)',
    prefix: '229',
    code: 'bj',
  },
  {
    name: 'Bermuda',
    prefix: '1',
    code: 'bm',
  },
  {
    name: 'Bhutan (འབྲུག)',
    prefix: '975',
    code: 'bt',
  },
  {
    name: 'Bolivia',
    prefix: '591',
    code: 'bo',
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    prefix: '387',
    code: 'ba',
  },
  {
    name: 'Botswana',
    prefix: '267',
    code: 'bw',
  },
  {
    name: 'Brazil (Brasil)',
    prefix: '55',
    code: 'br',
  },
  {
    name: 'British Indian Ocean Territory',
    prefix: '246',
    code: 'io',
  },
  {
    name: 'British Virgin Islands',
    prefix: '1',
    code: 'vg',
  },
  {
    name: 'Brunei',
    prefix: '673',
    code: 'bn',
  },
  {
    name: 'Bulgaria (България)',
    prefix: '359',
    code: 'bg',
  },
  {
    name: 'Burkina Faso',
    prefix: '226',
    code: 'bf',
  },
  {
    name: 'Burundi (Uburundi)',
    prefix: '257',
    code: 'bi',
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    prefix: '855',
    code: 'kh',
  },
  {
    name: 'Cameroon (Cameroun)',
    prefix: '237',
    code: 'cm',
  },
  {
    name: 'Canada',
    prefix: '1',
    code: 'ca',
  },
  {
    name: 'Cape Verde (Kabu Verdi)',
    prefix: '238',
    code: 'cv',
  },
  {
    name: 'Caribbean Netherlands',
    prefix: '599',
    code: 'bq',
  },
  {
    name: 'Cayman Islands',
    prefix: '1',
    code: 'ky',
  },
  {
    name: 'Central African Republic (République centrafricaine)',
    prefix: '236',
    code: 'cf',
  },
  {
    name: 'Chad (Tchad)',
    prefix: '235',
    code: 'td',
  },
  {
    name: 'Chile',
    prefix: '56',
    code: 'cl',
  },
  {
    name: 'China (中国)',
    prefix: '86',
    code: 'cn',
  },
  {
    name: 'Christmas Island',
    prefix: '61',
    code: 'cx',
  },
  {
    name: 'Cocos (Keeling) Islands',
    prefix: '61',
    code: 'cc',
  },
  {
    name: 'Colombia',
    prefix: '57',
    code: 'co',
  },
  {
    name: 'Comoros (‫جزر القمر‬‎)',
    prefix: '269',
    code: 'km',
  },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    prefix: '243',
    code: 'cd',
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    prefix: '242',
    code: 'cg',
  },
  {
    name: 'Cook Islands',
    prefix: '682',
    code: 'ck',
  },
  {
    name: 'Costa Rica',
    prefix: '506',
    code: 'cr',
  },
  {
    name: 'Côte d’Ivoire',
    prefix: '225',
    code: 'ci',
  },
  {
    name: 'Croatia (Hrvatska)',
    prefix: '385',
    code: 'hr',
  },
  {
    name: 'Cuba',
    prefix: '53',
    code: 'cu',
  },
  {
    name: 'Curaçao',
    prefix: '599',
    code: 'cw',
  },
  {
    name: 'Cyprus (Κύπρος)',
    prefix: '357',
    code: 'cy',
  },
  {
    name: 'Czech Republic (Česká republika)',
    prefix: '420',
    code: 'cz',
  },
  {
    name: 'Denmark (Danmark)',
    prefix: '45',
    code: 'dk',
  },
  {
    name: 'Djibouti',
    prefix: '253',
    code: 'dj',
  },
  {
    name: 'Dominica',
    prefix: '1',
    code: 'dm',
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    prefix: '1',
    code: 'do',
  },
  {
    name: 'Ecuador',
    prefix: '593',
    code: 'ec',
  },
  {
    name: 'Egypt (‫مصر‬‎)',
    prefix: '20',
    code: 'eg',
  },
  {
    name: 'El Salvador',
    prefix: '503',
    code: 'sv',
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    prefix: '240',
    code: 'gq',
  },
  {
    name: 'Eritrea',
    prefix: '291',
    code: 'er',
  },
  {
    name: 'Estonia (Eesti)',
    prefix: '372',
    code: 'ee',
  },
  {
    name: 'Eswatini',
    prefix: '268',
    code: 'sz',
  },
  {
    name: 'Ethiopia',
    prefix: '251',
    code: 'et',
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    prefix: '500',
    code: 'fk',
  },
  {
    name: 'Faroe Islands (Føroyar)',
    prefix: '298',
    code: 'fo',
  },
  {
    name: 'Fiji',
    prefix: '679',
    code: 'fj',
  },
  {
    name: 'Finland (Suomi)',
    prefix: '358',
    code: 'fi',
  },
  {
    name: 'France',
    prefix: '33',
    code: 'fr',
  },
  {
    name: 'French Guiana (Guyane française)',
    prefix: '594',
    code: 'gf',
  },
  {
    name: 'French Polynesia (Polynésie française)',
    prefix: '689',
    code: 'pf',
  },
  {
    name: 'Gabon',
    prefix: '241',
    code: 'ga',
  },
  {
    name: 'Gambia',
    prefix: '220',
    code: 'gm',
  },
  {
    name: 'Georgia (საქართველო)',
    prefix: '995',
    code: 'ge',
  },
  {
    name: 'Germany (Deutschland)',
    prefix: '49',
    code: 'de',
  },
  {
    name: 'Ghana (Gaana)',
    prefix: '233',
    code: 'gh',
  },
  {
    name: 'Gibraltar',
    prefix: '350',
    code: 'gi',
  },
  {
    name: 'Greece (Ελλάδα)',
    prefix: '30',
    code: 'gr',
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    prefix: '299',
    code: 'gl',
  },
  {
    name: 'Grenada',
    prefix: '1',
    code: 'gd',
  },
  {
    name: 'Guadeloupe',
    prefix: '590',
    code: 'gp',
  },
  {
    name: 'Guam',
    prefix: '1',
    code: 'gu',
  },
  {
    name: 'Guatemala',
    prefix: '502',
    code: 'gt',
  },
  {
    name: 'Guernsey',
    prefix: '44',
    code: 'gg',
  },
  {
    name: 'Guinea (Guinée)',
    prefix: '224',
    code: 'gn',
  },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    prefix: '245',
    code: 'gw',
  },
  {
    name: 'Guyana',
    prefix: '592',
    code: 'gy',
  },
  {
    name: 'Haiti',
    prefix: '509',
    code: 'ht',
  },
  {
    name: 'Honduras',
    prefix: '504',
    code: 'hn',
  },
  {
    name: 'Hong Kong (香港)',
    prefix: '852',
    code: 'hk',
  },
  {
    name: 'Hungary (Magyarország)',
    prefix: '36',
    code: 'hu',
  },
  {
    name: 'Iceland (Ísland)',
    prefix: '354',
    code: 'is',
  },
  {
    name: 'India (भारत)',
    prefix: '91',
    code: 'in',
  },
  {
    name: 'Indonesia',
    prefix: '62',
    code: 'id',
  },
  {
    name: 'Iran (‫ایران‬‎)',
    prefix: '98',
    code: 'ir',
  },
  {
    name: 'Iraq (‫العراق‬‎)',
    prefix: '964',
    code: 'iq',
  },
  {
    name: 'Ireland',
    prefix: '353',
    code: 'ie',
  },
  {
    name: 'Isle of Man',
    prefix: '44',
    code: 'im',
  },
  {
    name: 'Israel (‫ישראל‬‎)',
    prefix: '972',
    code: 'il',
  },
  {
    name: 'Italy (Italia)',
    prefix: '39',
    code: 'it',
  },
  {
    name: 'Jamaica',
    prefix: '1',
    code: 'jm',
  },
  {
    name: 'Japan (日本)',
    prefix: '81',
    code: 'jp',
  },
  {
    name: 'Jersey',
    prefix: '44',
    code: 'je',
  },
  {
    name: 'Jordan (‫الأردن‬‎)',
    prefix: '962',
    code: 'jo',
  },
  {
    name: 'Kazakhstan (Казахстан)',
    prefix: '7',
    code: 'kz',
  },
  {
    name: 'Kenya',
    prefix: '254',
    code: 'ke',
  },
  {
    name: 'Kiribati',
    prefix: '686',
    code: 'ki',
  },
  {
    name: 'Kosovo',
    prefix: '383',
    code: 'xk',
  },
  {
    name: 'Kuwait (‫الكويت‬‎)',
    prefix: '965',
    code: 'kw',
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    prefix: '996',
    code: 'kg',
  },
  {
    name: 'Laos (ລາວ)',
    prefix: '856',
    code: 'la',
  },
  {
    name: 'Latvia (Latvija)',
    prefix: '371',
    code: 'lv',
  },
  {
    name: 'Lebanon (‫لبنان‬‎)',
    prefix: '961',
    code: 'lb',
  },
  {
    name: 'Lesotho',
    prefix: '266',
    code: 'ls',
  },
  {
    name: 'Liberia',
    prefix: '231',
    code: 'lr',
  },
  {
    name: 'Libya (‫ليبيا‬‎)',
    prefix: '218',
    code: 'ly',
  },
  {
    name: 'Liechtenstein',
    prefix: '423',
    code: 'li',
  },
  {
    name: 'Lithuania (Lietuva)',
    prefix: '370',
    code: 'lt',
  },
  {
    name: 'Luxembourg',
    prefix: '352',
    code: 'lu',
  },
  {
    name: 'Macau (澳門)',
    prefix: '853',
    code: 'mo',
  },
  {
    name: 'Madagascar (Madagasikara)',
    prefix: '261',
    code: 'mg',
  },
  {
    name: 'Malawi',
    prefix: '265',
    code: 'mw',
  },
  {
    name: 'Malaysia',
    prefix: '60',
    code: 'my',
  },
  {
    name: 'Maldives',
    prefix: '960',
    code: 'mv',
  },
  {
    name: 'Mali',
    prefix: '223',
    code: 'ml',
  },
  {
    name: 'Malta',
    prefix: '356',
    code: 'mt',
  },
  {
    name: 'Marshall Islands',
    prefix: '692',
    code: 'mh',
  },
  {
    name: 'Martinique',
    prefix: '596',
    code: 'mq',
  },
  {
    name: 'Mauritania (‫موريتانيا‬‎)',
    prefix: '222',
    code: 'mr',
  },
  {
    name: 'Mauritius (Moris)',
    prefix: '230',
    code: 'mu',
  },
  {
    name: 'Mayotte',
    prefix: '262',
    code: 'yt',
  },
  {
    name: 'Mexico (México)',
    prefix: '52',
    code: 'mx',
  },
  {
    name: 'Micronesia',
    prefix: '691',
    code: 'fm',
  },
  {
    name: 'Moldova (Republica Moldova)',
    prefix: '373',
    code: 'md',
  },
  {
    name: 'Monaco',
    prefix: '377',
    code: 'mc',
  },
  {
    name: 'Mongolia (Монгол)',
    prefix: '976',
    code: 'mn',
  },
  {
    name: 'Montenegro (Crna Gora)',
    prefix: '382',
    code: 'me',
  },
  {
    name: 'Montserrat',
    prefix: '1',
    code: 'ms',
  },
  {
    name: 'Morocco (‫المغرب‬‎)',
    prefix: '212',
    code: 'ma',
  },
  {
    name: 'Mozambique (Moçambique)',
    prefix: '258',
    code: 'mz',
  },
  {
    name: 'Myanmar (Burma) (မြန်မာ)',
    prefix: '95',
    code: 'mm',
  },
  {
    name: 'Namibia (Namibië)',
    prefix: '264',
    code: 'na',
  },
  {
    name: 'Nauru',
    prefix: '674',
    code: 'nr',
  },
  {
    name: 'Nepal (नेपाल)',
    prefix: '977',
    code: 'np',
  },
  {
    name: 'Netherlands (Nederland)',
    prefix: '31',
    code: 'nl',
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    prefix: '687',
    code: 'nc',
  },
  {
    name: 'New Zealand',
    prefix: '64',
    code: 'nz',
  },
  {
    name: 'Nicaragua',
    prefix: '505',
    code: 'ni',
  },
  {
    name: 'Niger (Nijar)',
    prefix: '227',
    code: 'ne',
  },
  {
    name: 'Nigeria',
    prefix: '234',
    code: 'ng',
  },
  {
    name: 'Niue',
    prefix: '683',
    code: 'nu',
  },
  {
    name: 'Norfolk Island',
    prefix: '672',
    code: 'nf',
  },
  {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    prefix: '850',
    code: 'kp',
  },
  {
    name: 'North Macedonia (Северна Македонија)',
    prefix: '389',
    code: 'mk',
  },
  {
    name: 'Northern Mariana Islands',
    prefix: '1',
    code: 'mp',
  },
  {
    name: 'Norway (Norge)',
    prefix: '47',
    code: 'no',
  },
  {
    name: 'Oman (‫عُمان‬‎)',
    prefix: '968',
    code: 'om',
  },
  {
    name: 'Pakistan (‫پاکستان‬‎)',
    prefix: '92',
    code: 'pk',
  },
  {
    name: 'Palau',
    prefix: '680',
    code: 'pw',
  },
  {
    name: 'Palestine (‫فلسطين‬‎)',
    prefix: '970',
    code: 'ps',
  },
  {
    name: 'Panama (Panamá)',
    prefix: '507',
    code: 'pa',
  },
  {
    name: 'Papua New Guinea',
    prefix: '675',
    code: 'pg',
  },
  {
    name: 'Paraguay',
    prefix: '595',
    code: 'py',
  },
  {
    name: 'Peru (Perú)',
    prefix: '51',
    code: 'pe',
  },
  {
    name: 'Philippines',
    prefix: '63',
    code: 'ph',
  },
  {
    name: 'Poland (Polska)',
    prefix: '48',
    code: 'pl',
  },
  {
    name: 'Portugal',
    prefix: '351',
    code: 'pt',
  },
  {
    name: 'Puerto Rico',
    prefix: '1',
    code: 'pr',
  },
  {
    name: 'Qatar (‫قطر‬‎)',
    prefix: '974',
    code: 'qa',
  },
  {
    name: 'Réunion (La Réunion)',
    prefix: '262',
    code: 're',
  },
  {
    name: 'Romania (România)',
    prefix: '40',
    code: 'ro',
  },
  {
    name: 'Russia (Россия)',
    prefix: '7',
    code: 'ru',
  },
  {
    name: 'Rwanda',
    prefix: '250',
    code: 'rw',
  },
  {
    name: 'Saint Barthélemy',
    prefix: '590',
    code: 'bl',
  },
  {
    name: 'Saint Helena',
    prefix: '290',
    code: 'sh',
  },
  {
    name: 'Saint Kitts and Nevis',
    prefix: '1',
    code: 'kn',
  },
  {
    name: 'Saint Lucia',
    prefix: '1',
    code: 'lc',
  },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    prefix: '590',
    code: 'mf',
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    prefix: '508',
    code: 'pm',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    prefix: '1',
    code: 'vc',
  },
  {
    name: 'Samoa',
    prefix: '685',
    code: 'ws',
  },
  {
    name: 'San Marino',
    prefix: '378',
    code: 'sm',
  },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    prefix: '239',
    code: 'st',
  },
  {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    prefix: '966',
    code: 'sa',
  },
  {
    name: 'Senegal (Sénégal)',
    prefix: '221',
    code: 'sn',
  },
  {
    name: 'Serbia (Србија)',
    prefix: '381',
    code: 'rs',
  },
  {
    name: 'Seychelles',
    prefix: '248',
    code: 'sc',
  },
  {
    name: 'Sierra Leone',
    prefix: '232',
    code: 'sl',
  },
  {
    name: 'Singapore',
    prefix: '65',
    code: 'sg',
  },
  {
    name: 'Sint Maarten',
    prefix: '1',
    code: 'sx',
  },
  {
    name: 'Slovakia (Slovensko)',
    prefix: '421',
    code: 'sk',
  },
  {
    name: 'Slovenia (Slovenija)',
    prefix: '386',
    code: 'si',
  },
  {
    name: 'Solomon Islands',
    prefix: '677',
    code: 'sb',
  },
  {
    name: 'Somalia (Soomaaliya)',
    prefix: '252',
    code: 'so',
  },
  {
    name: 'South Africa',
    prefix: '27',
    code: 'za',
  },
  {
    name: 'South Korea (대한민국)',
    prefix: '82',
    code: 'kr',
  },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    prefix: '211',
    code: 'ss',
  },
  {
    name: 'Spain (España)',
    prefix: '34',
    code: 'es',
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    prefix: '94',
    code: 'lk',
  },
  {
    name: 'Sudan (‫السودان‬‎)',
    prefix: '249',
    code: 'sd',
  },
  {
    name: 'Suriname',
    prefix: '597',
    code: 'sr',
  },
  {
    name: 'Svalbard and Jan Mayen',
    prefix: '47',
    code: 'sj',
  },
  {
    name: 'Sweden (Sverige)',
    prefix: '46',
    code: 'se',
  },
  {
    name: 'Switzerland (Schweiz)',
    prefix: '41',
    code: 'ch',
  },
  {
    name: 'Syria (‫سوريا‬‎)',
    prefix: '963',
    code: 'sy',
  },
  {
    name: 'Taiwan (台灣)',
    prefix: '886',
    code: 'tw',
  },
  {
    name: 'Tajikistan',
    prefix: '992',
    code: 'tj',
  },
  {
    name: 'Tanzania',
    prefix: '255',
    code: 'tz',
  },
  {
    name: 'Thailand (ไทย)',
    prefix: '66',
    code: 'th',
  },
  {
    name: 'Timor-Leste',
    prefix: '670',
    code: 'tl',
  },
  {
    name: 'Togo',
    prefix: '228',
    code: 'tg',
  },
  {
    name: 'Tokelau',
    prefix: '690',
    code: 'tk',
  },
  {
    name: 'Tonga',
    prefix: '676',
    code: 'to',
  },
  {
    name: 'Trinidad and Tobago',
    prefix: '1',
    code: 'tt',
  },
  {
    name: 'Tunisia (‫تونس‬‎)',
    prefix: '216',
    code: 'tn',
  },
  {
    name: 'Turkey (Türkiye)',
    prefix: '90',
    code: 'tr',
  },
  {
    name: 'Turkmenistan',
    prefix: '993',
    code: 'tm',
  },
  {
    name: 'Turks and Caicos Islands',
    prefix: '1',
    code: 'tc',
  },
  {
    name: 'Tuvalu',
    prefix: '688',
    code: 'tv',
  },
  {
    name: 'U.S. Virgin Islands',
    prefix: '1',
    code: 'vi',
  },
  {
    name: 'Uganda',
    prefix: '256',
    code: 'ug',
  },
  {
    name: 'Ukraine (Україна)',
    prefix: '380',
    code: 'ua',
  },
  {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    prefix: '971',
    code: 'ae',
  },
  {
    name: 'United Kingdom',
    prefix: '44',
    code: 'gb',
  },
  {
    name: 'United States',
    prefix: '1',
    code: 'us',
  },
  {
    name: 'Uruguay',
    prefix: '598',
    code: 'uy',
  },
  {
    name: 'Uzbekistan (Oʻzbekiston)',
    prefix: '998',
    code: 'uz',
  },
  {
    name: 'Vanuatu',
    prefix: '678',
    code: 'vu',
  },
  {
    name: 'Vatican City (Città del Vaticano)',
    prefix: '39',
    code: 'va',
  },
  {
    name: 'Venezuela',
    prefix: '58',
    code: 've',
  },
  {
    name: 'Vietnam (Việt Nam)',
    prefix: '84',
    code: 'vn',
  },
  {
    name: 'Wallis and Futuna (Wallis-et-Futuna)',
    prefix: '681',
    code: 'wf',
  },
  {
    name: 'Western Sahara (‫الصحراء الغربية‬‎)',
    prefix: '212',
    code: 'eh',
  },
  {
    name: 'Yemen (‫اليمن‬‎)',
    prefix: '967',
    code: 'ye',
  },
  {
    name: 'Zambia',
    prefix: '260',
    code: 'zm',
  },
  {
    name: 'Zimbabwe',
    prefix: '263',
    code: 'zw',
  },
  {
    name: 'Åland Islands',
    prefix: '358',
    code: 'ax',
  },
];

export const defaultCountry = allCountries.find(
  (country) => country.code === 'ua'
)!;
