import {AbstractModelUpdated, Permission} from '@nuov.io/model';
import {User} from "./User";


/** The Class UserAuthentication. */
export class UserAuthentication extends AbstractModelUpdated {

  /** The user. */
  private readonly _user: User;
  /** The manufacturer id. */
  private readonly _manufacturerId: string | null;

  /**
   * Instantiates a new UserAuthentication.
   *
   * @param user the user
   * @param permission the permission
   * @param id the id
   * @param manufacturerId the manufacturer id
   * @param created the created date
   * @param updated the updated date
   * @param enabled the enabled flag
   */
  constructor(
    user: User,
    permission: Permission,
    id?: string,
    manufacturerId?: string | null,
    created?: Date,
    updated?: Date,
    enabled?: boolean) {
    super(id, created, updated, enabled);
    this._user = user;
    this._permission = permission;
    this._manufacturerId = manufacturerId !== undefined ? manufacturerId : null;
  }

  /** The permission. */
  private _permission: Permission;

  /**
   * Gets the permission.
   *
   * @return the permission
   */
  public get permission(): Permission {
    return this._permission;
  }

  /**
   * Sets the permission.
   *
   * @param permission the permission
   */
  public set permission(permission: Permission) {
    this._permission = permission;
  }

  /**
   * Gets the user.
   *
   * @return the user
   */
  public get user(): User {
    return this._user;
  }

  /**
   * Gets the manufacturer id.
   *
   * @returns the manufacturer id
   */
  public get manufacturerId(): string | null {
    return this._manufacturerId;
  }
}
