<div>
  <div
    *ngIf="tableHeaderConfig?.hasHeader"
    class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
  >
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          {{ tableHeaderConfig.tableTitle }}
        </h1>
        <p class="mt-2 text-sm text-gray-700">
          {{ tableHeaderConfig.tableDesc }}
        </p>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button
          *ngFor="let action of tableHeaderConfig.actions; let i = index"
          type="button"
          (click)="emitAction({ actionId: action.actionId})"
          class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          [ngClass]="{'ml-3': i !== 0}"
        >
          {{ action.name }}
        </button>
      </div>
    </div>
  </div>
  <div class="mt-8 flow-root overflow-hidden">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <ng-container *ngIf="!isLoading">
        <table class="w-full text-left">
          <thead class="bg-white">
            <tr>
              <th
                scope="col"
                *ngFor="let column of tableColumns; let i = index"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                [ngClass]="{
                  '!pl-0 relative isolate': i == 0,
                  'md:!table-cell':
                    !column.isMobileVisible && !column.isTabletVisible,
                  'sm:!table-cell': column.isTabletVisible,
                  hidden: !column.isMobileVisible
                }"
              >
                {{ column.title | titlecase }}
                <ng-container *ngIf="i === 0">
                  <div
                    class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200"
                  ></div>
                  <div
                    class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200"
                  ></div>
                </ng-container>
              </th>
              <th scope="col" class="relative py-3.5 pl-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data; let i = index">
              <td
                *ngFor="let column of tableColumns; let j = index"
                class="px-3 py-5 text-left text-sm font-semibold text-gray-900"
                [ngClass]="{
                  '!pl-0 relative isolate': j == 0,
                  'md:!table-cell':
                    !column.isMobileVisible && !column.isTabletVisible,
                  'sm:!table-cell': column.isTabletVisible,
                  hidden: !column.isMobileVisible
                }"
              >
                {{ item[column.title] }}
  
                <ng-container *ngIf="j === 0">
                  <div
                    class="absolute bottom-0 right-full h-px w-screen bg-gray-100"
                  ></div>
                  <div
                    class="absolute bottom-0 left-0 h-px w-screen bg-gray-100"
                  ></div>
                </ng-container>
              </td>
  
              <td class="relative py-4 pl-3 text-right text-sm font-medium">
                <ng-container *ngFor="let action of tableRowActions; let k = index">
                  <a
                    *ngIf="action.icon"
                    (click)="
                      emitAction({ actionId: action.actionId, dataItem: item })
                    "
                    class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                    [ngClass]="{'ml-3': k !== 0}"
                    [innerHtml]="action.icon | safe"
                    >{{ action.name }}</a
                  >
  
                  <a
                    *ngIf="!action.icon"
                    (click)="
                      emitAction({ actionId: action.actionId, dataItem: item })
                    "
                    class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                    [ngClass]="{'ml-3': k !== 0}"
                    >{{ action.name }}</a
                  >
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      

      <ng-container #loading *ngIf="isLoading">
        <div class="w-full my-4 flex justify-center">
          <div
            class="text-indigo-600 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span
              class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
              >Loading...</span
            >
          </div>
        </div>
        
      </ng-container>
    </div>
  </div>
</div>
