import { Component, Inject } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

import { DialogRef } from '../dialog-ref';
import { DIALOG_DATA, DialogConfig } from '../dialog.model';

@Component({
  selector: 'io-provetech-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem) scale(0.95)' }),
        animate('300ms ease-out', style({  opacity: 1, transform: 'translateY(0) scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0) scale(1)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem) scale(0.95)' }))
      ])
    ])
  ]
})
export class SuccessModalComponent {
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public config: DialogConfig
  ) {}

  /**
   * Close the dialog.
   * 
   * @param actionResult - shows which button was pressed
   */
  close(actionResult=false) {
    this.dialogRef.close(actionResult);
  }
}
