import { Route } from '@angular/router';

import { RoutesConstants } from '@io-provetech/models';

import { SigninContainerComponent } from './auth/signin-container/signin-container.component';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: RoutesConstants.manufacturer.login.route,
    pathMatch: 'full',
  },
  {
    path: RoutesConstants.manufacturer.login.route,
    component: SigninContainerComponent,
    title: RoutesConstants.manufacturer.login.title,
  },
  {
    path: 'manufacturer',
    loadChildren: () =>
      import('./manufacturer/manufacturer.module').then((m) => m.ManufacturerModule),
  },
];
