import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'io-provetech-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent {
  @Output() public selectedFile: EventEmitter<File> = new EventEmitter();

  onFileChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.selectedFile.emit(file);
    }
  }
}
