import { Inject, Injectable } from '@angular/core';
import { filter, tap, timer } from 'rxjs';

import {
  keepAliveInterval,
  KEEP_ALIVE_TOKEN,
  wakeUpInterval,
} from './polling.constants';

import { AuthService } from 'libs/auth/src/lib/auth.service';

@Injectable()
export class PollingService {
  /** Timer rxjs operator for keeping server alive. */
  private timer$ = timer(wakeUpInterval, keepAliveInterval);

  /**
   *
   * @param keepAlive - Injection token for keep alive var.
   */
  constructor(
    @Inject(KEEP_ALIVE_TOKEN) private keepAlive: boolean,
    private authService: AuthService
  ) {}

  /** Emitter for keeping server alive. */
  getPollingStream() {
    return this.timer$.pipe(
      tap(d => console.log(this.keepAlive, this.authService.isUserLoggedOut, d)),
      filter(() => this.keepAlive && !this.authService.isUserLoggedOut)
    );
  }
}
