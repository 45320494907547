import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';

import { ICountryCode, IPhoneNumber } from '../_models/country-codes.data';
import { allCountries, defaultCountry } from '../../assets/country-codes/country-codes';


@Component({
  selector: 'io-provetech-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
      multi: true
    }
  ]
})
export class PhoneNumberInputComponent implements ControlValueAccessor {
  @Input() options: ICountryCode[] = allCountries;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Output() valueChange = new EventEmitter<IPhoneNumber>();

  selectedValue: ICountryCode = defaultCountry;
  inputValue!: string;
  onChange: any = () => {};
  onTouched: any = () => {};

  phoneNumberUtil: PhoneNumberUtil;

  constructor() {
    this.phoneNumberUtil = PhoneNumberUtil.getInstance();
  }

  writeValue(value: IPhoneNumber): void {
    if (value) {
      const { countryCode, phoneNumber } = value;
      this.selectedValue = countryCode;
      this.inputValue = phoneNumber;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Not used in this example
  }

  onSelectChange(selectedValue: ICountryCode): void {
    this.selectedValue = selectedValue;
    this.onChange({ countryCode: this.selectedValue, phoneNumber: this.inputValue });
    this.valueChange.emit({ countryCode: this.selectedValue, phoneNumber: this.inputValue });
  }

  onInputChange(inputValue: string): void {
    this.inputValue = inputValue;
    this.onChange({ countryCode: this.selectedValue, phoneNumber: this.inputValue });
    this.valueChange.emit({ countryCode: this.selectedValue, phoneNumber: this.inputValue });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const phoneControl = (control.value as IPhoneNumber);
    if (!phoneControl) {
      return null;
    }
    const phoneNumber = '+' + phoneControl.countryCode.prefix + ' ' + phoneControl.phoneNumber;
    if (!phoneNumber) {
      return null;
    }
    try {
      const parsedPhoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(phoneNumber, this.selectedValue.code.toUpperCase());
      if (this.phoneNumberUtil.isValidNumber(parsedPhoneNumber) && this.phoneNumberUtil.getNumberType(parsedPhoneNumber) !== PhoneNumberType.UNKNOWN) {
        const formattedPhoneNumber = this.phoneNumberUtil.formatInOriginalFormat(parsedPhoneNumber);
        if (formattedPhoneNumber === phoneNumber) {
          return null;
        }
      }
      return { 'phoneNumber': true };
    } catch (error) {
      return { 'phoneNumber': true };
    }
  }
}