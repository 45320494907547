import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import {
  ITableHeader,
  ITableColumn,
  ITableAction,
  ActionEvent,
  IPaginationConfig,
} from './table.model';

@Component({
  selector: 'io-provetech-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  /** Table header config. */
  @Input() tableHeaderConfig!: ITableHeader;

  /** Table columns. */
  @Input() tableColumns!: ITableColumn[];

  /** Table row actions. */
  @Input() tableRowActions!: ITableAction[];

  /** Table data. */
  @Input() data!: any[];

  /** isLoading state. */
  @Input() isLoading = false;

  /** Table action events emitter. */
  @Output() actionEmitter = new EventEmitter<ActionEvent>();

  @Output() paginationEmitter = new EventEmitter<IPaginationConfig>();

  /** Paginated data. */
  pageData?: Array<any> = [];

  constructor(private cd: ChangeDetectorRef) {}

  /**
   * Emits table action.
   *
   * @param action
   */
  emitAction(action: { actionId: string; dataItem?: any }) {
    this.actionEmitter.emit(action);
  }
}
