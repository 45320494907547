/** The class Event. */
export class Event<T> {

    /** The subject. */
    private readonly _subject: string;
  
    /** The value. */
    private readonly _value: T | null;
  
    /**
     * Instantiates an Event.
     *
     * @param subject the subject
     * @param value the value
     */
    constructor(subject: string, value?: T) {
      this._subject = subject;
      this._value = value !== undefined ? value : null;
    }
  
    /**
     * Gets the subject.
     *
     * @returns the subject
     */
    public get subject(): string {
      return this._subject;
    }
  
    /**
     * Gets the subject.
     *
     * @returns the subject
     */
    public get value(): T | null {
      return this._value;
    }
  }