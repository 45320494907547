<div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
      <ng-container *ngIf="hasField('firstName')">
        <div class="col-span-3">
          <io-provetech-input
            label="First Name"
            placeholder="First Name"
            formControlName="firstName"
          ></io-provetech-input>
        </div>
      </ng-container>

      <ng-container *ngIf="hasField('lastName')">
        <div class="col-span-3">
          <io-provetech-input
            label="Last Name"
            placeholder="Last Name"
            formControlName="lastName"
          ></io-provetech-input>
        </div>
      </ng-container>

      <ng-container *ngIf="hasField('email')">
        <div class="col-span-full">
          <io-provetech-input
            label="Email"
            placeholder="Email"
            formControlName="email"
          ></io-provetech-input>
        </div>
      </ng-container>

      <ng-container *ngIf="hasField('phoneNumber')">
        <div class="col-span-full">
          <io-provetech-phone-number-input
            label="Phone Number"
            placeholder="Phone Number"
            formControlName="phoneNumber"
          ></io-provetech-phone-number-input>
        </div>
      </ng-container>

      <ng-container *ngIf="hasField('permission')">
        <div class="col-span-2">
          <io-provetech-input
            label="Permision"
            placeholder="Permision"
            formControlName="permission"
          ></io-provetech-input>
        </div>
      </ng-container>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-4">
      <io-provetech-button
        label="cancel"
        (onClick)="false"
      ></io-provetech-button>

      <io-provetech-button
        label="Save changes"
        color="primary"
        (onClick)="onSubmit()"
      ></io-provetech-button>
    </div>
  </form>
</div>
