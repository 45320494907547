import { InjectionToken } from '@angular/core';

/** Toast config injector. */
export const TOAST_CONFIG_TOKEN = new InjectionToken('TOAST_DATA');

/** Toast config interface. */
export interface ToastConfig {
  type?: ToastType;
  text?: string;
  animation?: {
    fadeIn: number,
    fadeOut: number,
  }
}

/** Toast type. */
export type ToastType = 'warning' | 'info' | 'success';

/** Deafult toast config. */
export const defaultToastConfig: ToastConfig = {
  animation: {
    fadeOut: 2500,
    fadeIn: 300,
  },
};
