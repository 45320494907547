import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Safe pipe for using with svg icons,
 */
@Pipe({ name: 'safe' })
export class SafeHtml {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
