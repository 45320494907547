import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { IAddress, ICountryCode } from '../_models/country-codes.data';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'io-provetech-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true
    },
  ]
})
export class AddressInputComponent implements ControlValueAccessor {
  @Input() options!: ICountryCode[];
  @Input() label!: string;
  @Input() placeholder!: string;
  @Output() valueChange = new EventEmitter<IAddress>();

  selectedValue!: ICountryCode;
  inputValue!: string;
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: IAddress): void {
    if (value) {
      const { countryCode, address } = value;
      this.selectedValue = countryCode;
      this.inputValue = address;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Not used in this example
  }

  onSelectChange(selectedValue: ICountryCode): void {
    this.selectedValue = selectedValue;
    this.onChange({ countryCode: this.selectedValue, address: this.inputValue });
    this.valueChange.emit({ countryCode: this.selectedValue, address: this.inputValue });
  }

  onInputChange(inputValue: string): void {
    this.inputValue = inputValue;
    this.onChange({ countryCode: this.selectedValue, address: this.inputValue });
    this.valueChange.emit({ countryCode: this.selectedValue, address: this.inputValue });
  }
}
