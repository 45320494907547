import { Injectable } from '@angular/core';

// See:
// https://www.rfc-editor.org/rfc/rfc5424
// https://codemag.com/Article/1711021/Logging-in-Angular-Applications

/**
 * The enum LogLevel.
 */
enum LogLevel {
  None = -10,
  All = -1,
  Emergency = 0, // system is unusable
  Alert = 1, // action must be taken immediately
  Critical = 2, // critical conditions
  Error = 3, // error conditions
  Warning = 4, // warning conditions
  Notice = 5, // normal but significant condition
  Informational = 6, // informational messages
  Debug = 7 // debug-level messages
}

/**
 * The class LoggingService.
 */
@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  /** The constructor. */
  constructor() {
    // log instantiation
    this.informational(this.constructor.name + ' instantiated.');
  }

  /**
   * Adds an alert message to the logs.
   *
   * (action must be taken immediately)
   * @param message the message
   */
  public alert(message: any): void {
    console.log(this.format(message, LogLevel.Alert));
  }

  /**
   * Adds a critical message to the logs.
   *
   * (critical conditions)
   * @param message the message
   */
  public critical(message: any): void {
    console.log(this.format(message, LogLevel.Critical));
  }

  /**
   * Adds a debug message to the logs.
   *
   * (debug-level messages)
   * @param message the message
   */
  public debug(message: any): void {
    console.log(this.format(message, LogLevel.Debug));
  }

  /**
   * Adds an emergency message to the logs.
   *
   * (system is unusable)
   * @param message the message
   */
  public emergency(message: any): void {
    console.log(this.format(message, LogLevel.Emergency));
  }

  /**
   * Adds an error message to the logs.
   *
   * (error conditions)
   * @param message the message
   */
  public error(message: any): void {
    console.log(this.format(message, LogLevel.Error));
  }

  /**
   * Adds an informational message to the logs.
   *
   * (informational messages)
   * @param message the message
   */
  public informational(message: any): void {
    console.log(this.format(message, LogLevel.Informational));
  }

  /**
   * Adds a notice message to the logs.
   *
   * (normal but significant condition)
   * @param message the message
   */
  public notice(message: any): void {
    console.log(this.format(message, LogLevel.Notice));
  }

  /**
   * Adds a warning message to the logs.
   *
   * (warning conditions)
   * @param message the message
   */
  public warning(message: any): void {
    console.log(this.format(message, LogLevel.Warning));
  }

  /**
   * Formats the message.
   *
   * @param message the message
   * @param logLevel the log level
   */
  private format(message: any, logLevel: LogLevel): string {
    let entry: string = (this.getLogLevel(logLevel) + ": ").padEnd(16, " ");
    entry += new Date().toISOString() + ": ";
    entry += JSON.stringify(message);
    return entry;
  }

  /**
   * Gets the log level.
   *
   * @param logLevel the log level enum
   * @return the log level as a string
   */
  private getLogLevel(logLevel: LogLevel): string {
    switch (logLevel) {
      case LogLevel.All:
        return 'All';
      case LogLevel.None:
        return 'None';
      case LogLevel.Emergency:
        return 'Emergency';
      case LogLevel.Alert:
        return 'Alert';
      case LogLevel.Critical:
        return 'Critical';
      case LogLevel.Error:
        return 'Error';
      case LogLevel.Warning:
        return 'Warning';
      case LogLevel.Notice:
        return 'Notice';
      case LogLevel.Informational:
        return 'Informational';
      case LogLevel.Debug:
        return 'Debug';
    }
  }
}
