import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'io-provetech-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
  selectedFile!: File;

  handleInputEvent(file: File) {
    this.selectedFile = file;

    this.generatePreviewURL();

    this.fileEvent.emit(this.selectedFile);
  }
  @Input() public fileUrl!: string | null;
  @Output() public fileEvent: EventEmitter<File> = new EventEmitter<File>();

  generatePreviewURL(): void {
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileUrl = e.target.result;

      };
      reader.readAsDataURL(this.selectedFile);
    } else {
      this.fileUrl = null;
    }
  }

  clear(): void {
    this.fileUrl = null;
  }
}
