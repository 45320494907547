import { Component, Inject } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { catchError, throwError } from 'rxjs';

import { DialogRef } from '../dialog-ref';
import { DIALOG_DATA, DialogConfig } from '../dialog.model';

@Component({
  selector: 'io-provetech-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem) scale(0.95)' }),
        animate('300ms ease-out', style({  opacity: 1, transform: 'translateY(0) scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0) scale(1)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem) scale(0.95)' }))
      ])
    ])
  ]
})
export class WarningModalComponent {
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public config: DialogConfig
  ) {}

  /**
   * Close the dialog without result.
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * Submit the dialog.
   */
  submit() {
    if (this.config.action) {
      this.config.action(this.config.params?.['id'], this.config.params?.['params'])
        .pipe(
          catchError(error => throwError(() => error))
        )
        .subscribe(() => {
          this.dialogRef.close(true);
        });
    }
  }
}
