import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'io-provetech-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {

  /** Error code from route data. */
  errorCode = this.router.getCurrentNavigation()?.extras.state?.['errorCode'];

  constructor(private router: Router) {}
}
