import { Observable } from 'rxjs';

import { Manufacturer, Product } from '@io-provetech/models';
import { Page } from '@nuov.io/spring/build/esm/src/Page';

import { AbstractHttpService } from '../http.service';
import {
  HeaderLike,
  HttpDeleteItem,
  HttpGetCollection,
  HttpGetItem,
  HttpPatchItem,
  HttpPostItem,
  IRequestOptions,
  MANUFACTURER_ID,
} from '../http.constants';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';


/**
 * The class ManufacturerHttpService.
 */
@Injectable()
export class ProductHttpService
  extends AbstractHttpService<Product>
  implements
    HttpDeleteItem<Product>,
    HttpGetCollection<Product>,
    HttpGetItem<Product>,
    HttpPatchItem<Product>,
    HttpPostItem<Product>
{
  getCollectionPath(params: Params): string {
    return `/manufacturers/${params[MANUFACTURER_ID]}/products`;
  }

  public override getCollection(params: Params, filterParams?: HttpParams): Observable<Page<Product>> {
    return super.getCollection(params, filterParams);
  }

  public override getItem(id: string, params: Params, queryParameters?: unknown[], headers?: HeaderLike[]): Observable<Product> {
    return super.getItem(id, params, queryParameters, headers);
  }

  public override postItem(
    model: Product,
    params: Params,
    options?: IRequestOptions
  ): Observable<Product> {
    return super.postItem(model, params, options);
  }

  public override patchItem(
    id: string,
    model: Product,
    params: Params,
  ): Observable<Product> {
    return super.patchItem(id, model, params);
  }

  public override deleteItem(id: string, params: Params): Observable<any> {
    return super.deleteItem(id, params);
  }
}
