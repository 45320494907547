<div class="w-full h-full relative">
  <io-provetech-button
  (onClick)="clear()"
  styles="absolute top-[11px] right-[11px] p-3 bg-red-500 rounded"
>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1667 3.66667L10.5885 11.7617C10.5386 12.4594 9.95803 13 9.25851 13H3.74149C3.04197 13 2.46139 12.4594 2.41155 11.7617L1.83333 3.66667M5.16667 6.33333V10.3333M7.83333 6.33333V10.3333M8.5 3.66667V1.66667C8.5 1.29848 8.20152 1 7.83333 1H5.16667C4.79848 1 4.5 1.29848 4.5 1.66667V3.66667M1.16667 3.66667H11.8333"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</io-provetech-button>
<img
  [src]="fileUrl"
  class="rounded-lg"
  [ngClass]="{ 'w-full h-full': fileUrl }"
/>
</div>
