import { Params } from '@angular/router';

/** Commin Nav Tab interface. */
export interface INavTab {
  name: string;
  route: string;
}

/** Commin Menu Item interface. */
export interface IMenuItem {
  name: string;
  action: () => void;
}

/** Page Heading Action interface.  */
export interface IHeadingAction {
  name: string;
  action: () => void;
  styling?: 'default' | 'primary'
}

/** Breadcrumb interface. */
export interface IBreadcrumbInterface {
  label: string;
  params: Params;
  queryParams: Params;
  url: string;
}

/** Breadcrumb data label. */
export const ROUTER_DATA_BREADCRUMB = 'breadcrumb';

/** Breadcrumb data path. */
export const ROUTER_DATA_PATH = 'path';
