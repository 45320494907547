import { InjectionToken, Injector, Type } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { Params } from '@angular/router';
import { DialogRef } from './dialog-ref';

export interface DialogAction {
  id: string;
  content: string;
  action: (...params: any) => any;
  params: Params
  styles?: string;
}

/** Dialog config injector. */
export const DIALOG_DATA = new InjectionToken<DialogConfig>('DIALOG_DATA');

/** Dialog config interface. */
export interface DialogConfig {
  dialogType?: 'ADD' | 'EDIT' | 'EDIT_PRODUCTION';

  hasCancelButton?: boolean;
  hasDismissButton?: boolean;
  dialogTitle?: string;
  dialogText?: string;
  
  actionButtonText?: string;
  data?: (model: any) => any;
  action?: (...params: any) => any;
  params?: Params;
  
  component?: Type<any>;
  injectedValue?: any;

}

export interface DynamicDialogComponent {
  dialogType?: 'ADD' | 'EDIT';
  submitButtonText?: string;
  dynamicFormModel?: IDynamicFormModel[];
  data?: (model: any) => any;
  action?: (...params: any) => any,
  params?: any;
  dialogRef: DialogRef;
}

export interface IDynamicFormModel {
  id: string;
  label?: string;
  value: any;
  validators?: ValidatorFn[];
}