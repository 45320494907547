import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

/** Custom directive.
 *  Emit value if click was done ouside the chosen element.
 */
@Directive({
  selector: '[ioProvetechClickOutside]',
})
export class ClickOutsideDirective {
  /** Event emitter. */
  @Output() ioProvetechClickOutside = new EventEmitter<EventTarget>();

  constructor(private elementRef: ElementRef) { }

  /**
   * Event listener method, emit value if click was done outside the element.
   * 
   * @param target 
   */
  @HostListener('document:click', ['$event.target'])
  public onClick(target: EventTarget) {    
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.ioProvetechClickOutside.emit(target);
    }
  }
}