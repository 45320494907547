import {AbstractModelUpdated} from '@nuov.io/model';
import {BigIntValidator, StringValidator, UrlValidator} from "@nuov.io/validator";
import {Noun} from "@nuov.io/sentence";

/** The Class Image. */
export class Image extends AbstractModelUpdated {

  /** The constant MAXIMUM_URL_LENGTH. */
  public static readonly MAXIMUM_URL_LENGTH: bigint = BigInt(1020);

  /** The constant MINIMUM_HEIGHT. */
  public static readonly MINIMUM_HEIGHT: bigint = BigInt(1200);

  /** The constant MAXIMUM_HEIGHT. */
  public static readonly MAXIMUM_HEIGHT: bigint = BigInt(1200);

  /** The constant MINIMUM_WIDTH. */
  public static readonly MINIMUM_WIDTH: bigint = BigInt(750);

  /** The constant MAXIMUM_WIDTH. */
  public static readonly MAXIMUM_WIDTH: bigint = BigInt(750);

  /** The url. */
  private readonly _url: string;

  /** The height. */
  private readonly _height: bigint;

  /** The width. */
  private readonly _width: bigint;

  /**
   * Instantiates a new Image.
   *
   * @param url the url
   * @param height the height
   * @param width the width
   * @param id the id
   * @param created the created date
   * @param updated the updated date
   * @param enabled the enabled flag
   */
  constructor(
      url: string,
      height: bigint,
      width: bigint,
    id?: string,
      created?: Date,
      updated?: Date,
      enabled?: boolean) {
    super(id, created, updated, enabled);

    // url
    UrlValidator.the(Noun.ARGUMENT, "url", url).validate();
    StringValidator.the(Noun.ARGUMENT, "url", url).maximumLength(Image.MAXIMUM_URL_LENGTH).validate();
    this._url = url;

    // height
    BigIntValidator.the(Noun.ARGUMENT, "height", height).minimum(Image.MINIMUM_HEIGHT).maximum(Image.MAXIMUM_HEIGHT).validate();
    this._height = height;

    // width
    BigIntValidator.the(Noun.ARGUMENT, "width", width).minimum(Image.MINIMUM_WIDTH).maximum(Image.MAXIMUM_WIDTH).validate();
    this._width = width;
  }

  /**
   * Gets the url.
   *
   * @return the url
   */
  public get url() {
    return this._url;
  }

  /**
   * Gets the height.
   *
   * @return the height
   */
  public get height(): bigint {
    return this._height;
  }

  /**
   * Gets the width.
   *
   * @return the width
   */
  public get width(): bigint {
    return this._width;
  }
}
