<div>
    <label
      for="phone-number"
      class="block text-sm font-medium leading-5 text-gray-900"
      >{{ label }}</label
    >
    <div class="relative mt-2 rounded-md shadow-sm">
        <div class="absolute inset-y-0 left-0 flex items-center">
            <label for="country" class="sr-only">Country</label>
            <select
              (ngModelChange)="onSelectChange($event)"
              id="country"
              name="country"
              autocomplete="country"
              class="h-full !appearance-none !outline-none rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 sm:text-sm"
              [ngModel]="selectedValue"

            >
              <option *ngFor="let country of options" [ngValue]="country">{{ country.code | uppercase }}</option>
            </select>
        </div>
        

        <input
            type="text"
            name="phone-number"
            id="phone-number"
            class="block w-full appearance-none outline-none rounded-md border border-gray-300 py-2 pl-20 text-gray-900 placeholder:text-gray-400 focus:ring-indigo-500 sm:text-sm"
            [(ngModel)]="inputValue"
            [type]="'text'"
            (ngModelChange)="onInputChange($event)"
            [placeholder]="placeholder"
        />
    </div>
  </div>