import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'io-provetech-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() styles: string = '';

  /** Button color var. */
  @Input() color: 'primary' | 'default' = 'default';

  /** The flag for button disabled state. */
  @Input() disabled?: boolean;

  /** The label. */
  @Input() label?: string;

  /** The click event emitter. */
  @Output() onClick = new EventEmitter<any>();

  /**
   * Method handle button click event.
   * 
   * @param $event 
   */
  handleOnClick($event: Event) {
    $event.preventDefault();
    this.onClick.emit();
  }

  getStyling(): string {
    const defaultStyle = 'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm sm:col-start-1 sm:mt-0 disabled:cursor-not-allowed';
    if (!this.styles && this.color === 'default') {
      return defaultStyle + ' bg-white text-gray-900 border border-gray-300 hover:!border-gray-900 disabled:!border-gray-400 disabled:!text-gray-400'
    } else if (!this.styles && this.color === 'primary') {
      return defaultStyle + ' text-white bg-gray-700 hover:!bg-gray-900 disabled:!bg-gray-400';
    }

    return this.styles;
  }
}
