import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { catchError, from, mergeMap, Observable, of, tap, throwError } from 'rxjs';

import { LocalStorageService, LSKeys } from '@io-provetech/utils/localstorage-service';
import { AbstractHttpService, API_URL } from '@io-provetech/utils/http-service';
import { User } from '@io-provetech/models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService extends AbstractHttpService<User> {
  protected override getCollectionPath(): string {
    return '/account/user';
  }
  /** Local storage key for user object. */
  private userLSKey = LSKeys.user;

  // Save logged in user data.
  userData!: firebase.default.User;

  /** Assistant var for wake up call.
   *  Checks whether user fails auth or logs out.
   *  By default is set to false.
   */
  isUserLoggedOut = false;

  /**
   * Constructor. Check the authState and set jwt token pair to local storage
   *
   * @param afAuth
   * @param lsService
   */
  constructor(
    http: HttpClient,
    @Inject(API_URL) apiUrl: string,
    private afAuth: AngularFireAuth,
    private lsService: LocalStorageService,
  ) {
    super(http, apiUrl);
    this.afAuth.authState
      .pipe(
        mergeMap((user) => {
          if (user) {
            this.userData = user;
            this.isUserLoggedOut = false;

            return from(user?.getIdToken());
          }
          return of(null);
        })
      )
      .subscribe((token) => {
        const lsUser = this.lsService.getItem(this.userLSKey);
        
        if (token && lsUser) {
          this.lsService.setItem(this.userLSKey, { ...lsUser, token });
        } else if (token && !lsUser) {
          this.lsService.setItem(this.userLSKey, { token });
        } else {
          this.lsService.setItem(this.userLSKey, null);
        }
      });
  }

  /**
   * Method uses firebase auth module for signing in.
   * 
   * @param email
   * @param password
   */
  signInwithEmailPassword(
    email: string,
    password: string
  ): Observable<User> {
    return from(this.afAuth.signInWithEmailAndPassword(email, password))
      .pipe(
        catchError((error) => {
          this.isUserLoggedOut = true;

          return throwError(() => error);
        }),
        mergeMap(user => {
          if (user && user.user) {
            return from(user.user.getIdToken());
          }

          return throwError(() => new Error(''));
        })
      ).pipe(
        mergeMap((token) => {
          return this.getItem(
            '',
            null,
            [],
            [{'authorization': `Bearer ${token}`}]
          );
        }),
        tap(user => {
          const token = this.lsService.getItem<{ token: string }>(this.userLSKey);
          this.lsService.setItem(this.userLSKey, {
            ...user,
            ...token
          });
        })
      );
  }

  getToken(): string {
    return this.lsService.getItem<{ token: string }>(this.userLSKey)?.token;
  }

  /** Firebase getgetIdToken method with true param works as refresh token method */
  refreshToken(): Observable<string> {
    return from(this.userData.getIdToken(true));
  }

  /** Sign out method. */
  logOut(): Observable<void> {
    this.isUserLoggedOut = true;
    return from(this.afAuth.signOut());
  }
}
