import {AbstractModelUpdated} from '@nuov.io/model';
import {StringValidator} from "@nuov.io/validator";
import {Noun} from "@nuov.io/sentence";

/** The Class Product. */
export class Product extends AbstractModelUpdated {

    /** The constant MAXIMUM_NAME_LENGTH. */
    public static readonly MAXIMUM_NAME_LENGTH: bigint = BigInt(50);

    /** The manufacturer id. */
    private readonly _manufacturerId: string;

    /** The name. */
    private _name: string;

    /**
     * Instantiates a new Product.
     *
     * @param id the id
     * @param manufacturerId the manufacturer id
     * @param name the name
     * @param created the created date
     * @param updated the updated date
     * @param enabled the enabled flag
     */
    constructor(
      manufacturerId: string,
        name: string,
        id?: string,
        created?: Date,
        updated?: Date,
        enabled?: boolean) {
        super(id, created, updated, enabled);
        this._manufacturerId = manufacturerId;
        this._name = name;
    }

    /**
     * Gets the manufacturer id.
     *
     * @return the manufacturer id
     */
    public get manufacturerId(): string {
        return this._manufacturerId;
    }

    /**
     * Gets the name.
     *
     * @return the name
     */
    public get name(): string {
        return this._name;
    }

    /**
     * Sets the name.
     *
     * @param name the name
     */
    public set name(name: string) {
        name = name.trim();
        StringValidator.the(Noun.ARGUMENTS, "name", name).maximumLength(Product.MAXIMUM_NAME_LENGTH).validate();
        this._name = name;
    }
}
