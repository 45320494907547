<div class="relative">
  <label class="absolute inline-flex bg-white p-0 px-[3px] top-[-0.7rem] left-[10px] text-sm font-medium text-gray-700">{{ label }}</label>
  <div class="mt-2">
    <input
      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      [(ngModel)]="value"
      [type]="type"
      [disabled]="disabled"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      (input)="onChange($event)"
      [placeholder]="placeholder"
      [readOnly]="readonly"
    />
  </div>
  <p *ngIf="maxLength" class="text-xs font-normal text-[#afb1b6] text-right">
    {{ value.length }}/{{ maxLength }}
  </p>
  <div class="mt-2 text-sm text-red-600">
    <ng-content></ng-content>
  </div>
</div>
