import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UtilsLocalstorageModule } from '@io-provetech/utils/localstorage-service';

import { AuthService } from './auth.service';

@NgModule({
  imports: [CommonModule, UtilsLocalstorageModule],
  providers: [AuthService]
})
export class AuthModule {}
