<!-- WRAPPER -->
<div
  class="relative z-10"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div
      class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"
    >
      <div
        class="w-full sm:w-1/2 lg:w-1/3 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
      >
        <!-- MAIN -->
        <div class="flex flex-col gap-y-6">
          <!-- HEADER -->
          <div class="flex justify-between items-start">
            <div class="flex flex-col gap-y-2">
              <!-- TITLE -->
              <h2 class="text-lg font-medium text-gray-900">{{ config.dialogTitle }}</h2>
              <!-- DESCRIPTION -->
              <p class="text-sm font-normal text-gray-500">{{ config.dialogText }}</p>
            </div>

            <!-- CLOSE BUTTON -->
            <io-provetech-button
              styles="p-2 rounded bg-white border border-gray-700 rounded"
              (onClick)="close()"
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 9L9 1M1 1L9 9"
                  stroke="#374151"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </io-provetech-button>
          </div>

          <!-- BODY -->
          <div class="flex flex-col">
            <ng-container #vcr></ng-container>
          </div>

          <!-- ACTION BUTTONS -->
          <div *ngIf="false" class="flex justify-between gap-x-4">
            <io-provetech-button
              class="flex-1"
              styles="w-full py-[9px] text-sm font-medium text-gray-900 border border-gray-300 text-center rounded-md"
              (onClick)="close()"
              >Cancel</io-provetech-button
            >

            <io-provetech-button
              class="flex-1"
              styles="w-full py-[9px] text-sm font-medium text-white bg-[#374151] text-center rounded-md"
              (onClick)="submit()"
              >{{ config.actionButtonText ?? 'Submit' }}</io-provetech-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
