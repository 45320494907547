import { Component, Input } from '@angular/core';
import { Model } from '@nuov.io/model';
import { Page } from '@nuov.io/spring/build/esm/src/Page';
import { Observable } from 'rxjs';

export class CardDefinition<T extends Model> {
  constructor(
    public data: (model: T) => any,
    public cardAction: (model: T) => void,
    public image?: (model: T) => string,
    public action?: (model: T) => void
  ) {
    this.action = action === undefined ? undefined : action;
  }
}

@Component({
  selector: 'io-provetech-grid-list',
  templateUrl: './grid-list.component.html',
  styleUrls: ['./grid-list.component.scss'],
})
export class GridListComponent<T extends Model> {

  /** The list definition. */
  @Input() cardDefinition!: CardDefinition<T>;

  /** Table page of elements. */
  @Input() page!: Page<T> | null;

  public get isLoading(): boolean {
    return this.page === null;
  }

  public get isEmpty(): boolean {
    return (this.page !== null && this.page.numberOfElements === 0);
  }
}
