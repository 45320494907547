import {AbstractCompositeUpdated} from '@nuov.io/model';
import {Image} from "./Image";
import {Inspection} from "./Inspection";
import {Nfc} from "./Nfc";
import {Noun} from "@nuov.io/sentence";
import {Manufacturer} from "./Manufacturer";
import {Product} from "./Product";
import {Production} from "./Production";
import {UnitTag} from "./UnitTag";
import {UuidValidator} from "@nuov.io/validator";

/** The Class UnitComposite. */
export class UnitComposite extends AbstractCompositeUpdated {

  /** The manufacturer. */
  private readonly _manufacturer: Manufacturer;
  /** The product. */
  private readonly _product: Product;
  /** The production. */
  private readonly _production: Production;
  /** The image. */
  private readonly _image: Image;
  /** The list of nfcs. */
  private readonly _nfcs: Nfc[] | null;
  /** The list of inspections. */
  private readonly _inspections: Inspection[] | null;
  /** The list of tags. */
  private readonly _unitTags: UnitTag[] | null;

  /**
   * Instantiates a new UnitComposite.
   *
   * @param id the id
   * @param userUnitId the user unit id
   * @param manufacturer the manufacturer
   * @param product the product
   * @param production the production
   * @param image the image
   * @param nfcs the nfcs
   * @param inspections the inspections
   * @param unitTags the unit tags
   * @param created the created date
   * @param updated the updated date
   * @param enabled the enabled flag
   */
  constructor(
    id: string,
    userUnitId: string | null,
    manufacturer: Manufacturer,
    product: Product,
    production: Production,
    image: Image,
    nfcs?: Nfc[] | null,
    inspections?: Inspection[] | null,
    unitTags?: UnitTag[] | null,
    created?: Date,
    updated?: Date,
    enabled?: boolean) {
    super(id, created, updated, enabled);

    // user unit id
    UuidValidator.the(Noun.ARGUMENT, "userUnitId", userUnitId).allowNull().validate();
    this._userUnitId = userUnitId;

    // manufacturer
    this._manufacturer = manufacturer;

    // product
    this._product = product;

    // production
    this._production = production;

    // image
    this._image = image;

    // nfcs
    this._nfcs = nfcs !== undefined ? nfcs : null;

    // inspections
    this._inspections = inspections !== undefined ? inspections : null;

    // unit tags
    this._unitTags = unitTags !== undefined ? unitTags : null;
  }

  /** The user unit id. */
  private _userUnitId: string | null;

  /**
   * Gets the user unit id.
   *
   * @return the user unit id
   */
  public get userUnitId(): string | null {
    return this._userUnitId;
  }

  /**
   * Sets the user unit id.
   *
   * @param userUnitId the user unit id
   */
  public set userUnitId(userUnitId: string | null) {
    UuidValidator.the(Noun.ARGUMENT, "userUnitId", userUnitId).allowNull().validate();
    this._userUnitId = userUnitId;
  }

  /**
   * Gets the manufacturer.
   *
   * @return the manufacturer
   */
  public get manufacturer(): Manufacturer {
    return this._manufacturer;
  }

  /**
   * Gets the product.
   *
   * @return the product
   */
  public get product(): Product {
    return this._product;
  }

  /**
   * Gets the production.
   *
   * @return the production
   */
  public get production(): Production {
    return this._production;
  }

  /**
   * Gets the image.
   *
   * @return the image
   */
  public get image(): Image {
    return this._image;
  }

  /**
   * Gets the list of nfcs.
   *
   * @return the list of nfcs
   */
  public get nfcs(): Nfc[] | null {
    return this._nfcs;
  }

  /**
   * Gets the list of inspections.
   *
   * @return the list of inspections
   */
  public get inspections(): Inspection[] | null {
    return this._inspections;
  }

  /**
   * Gets the list of tags.
   *
   * @return the list of tags
   */
  public get unitTags(): UnitTag[] | null {
    return this._unitTags;
  }
}
