import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ErrorMessages } from 'libs/models/src/lib/validatorErrors';

@Component({
  selector: 'io-provetech-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  /** The sign in event emitter to sign in container component to use in apps. */
  @Output() signin = new EventEmitter<{ email: string, password: string }>();

  /** The form group. */
  signinForm: FormGroup;

  /** The form group getter. */
  get form(): FormGroup {
    return this.signinForm;
  }

  /**
   * Constructor. Inits the sign in form.
   * 
   * @param fb - FormBuilder object for
   */
  constructor(private fb: FormBuilder) {
    this.signinForm = this.fb.group({
      email: [ '', Validators.compose( [ Validators.required, Validators.email ] ) ],
      password: [ '', Validators.compose( [ Validators.required, Validators.minLength(8) ] ) ]
    });
  }

  /**
   * Method checks if the form control has validation errors and return error. 
   * 
   * @param formControl - abstract control
   * @returns string value of error
   */
  getError(formControl: AbstractControl): string {
    for (const error in formControl.errors) {
      if (error in ErrorMessages) {
        return ErrorMessages[error];
      }
    }
    return '';
  }

  /** Method checks the form validity and emit submit event to sign in container. */
  onSubmit(): void {
    if (!this.signinForm.valid) {
      return;
    }
    const [email, password] = [this.signinForm.value.email, this.signinForm.value.password];
    this.signin.emit({ email, password });
  }
}
