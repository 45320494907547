export interface ITableHeader {
  hasHeader: boolean;
  tableTitle?: string;
  tableDesc?: string;
  actions: ITableAction[];
}

export interface ITableColumn {
  title: string;
  isMobileVisible?: boolean;
  isTabletVisible?: boolean;
  // ...more
}

export interface ITableAction {
  name: string;
  icon?: string;
  actionId: string;
}

export interface IPaginationConfig {
  pageIndex: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
}

export interface ActionEvent {
  actionId: string;
  dataItem?: any;
}

/** Table actions ids. */
export enum TableActions {
  ADD = 'ADD',
  EDIT = 'EDIT',
  REMOVE = 'REMOVE',
  VIEW = 'VIEW',
}
