import {AbstractModelUpdated, Model, User as UserInterface} from '@nuov.io/model';
import {UserType} from "./UserType";
import {PhoneNumber} from "./PhoneNumber";


/** The Class User. */
export class User extends AbstractModelUpdated implements UserInterface {

  /** The constant MAXIMUM_AUTHENTICATION_ID_LENGTH. */
  public static readonly MAXIMUM_AUTHENTICATION_ID_LENGTH: bigint = BigInt(100);

  /** The constant MAXIMUM_FIRST_NAME_LENGTH. */
  public static readonly MAXIMUM_FIRST_NAME_LENGTH: bigint = BigInt(60);

  /** The constant MAXIMUM_LAST_NAME_LENGTH. */
  public static readonly MAXIMUM_LAST_NAME_LENGTH: bigint = BigInt(60);

  /** The constant MAXIMUM_EMAIL_LENGTH. */
  public static readonly MAXIMUM_EMAIL_LENGTH: bigint = BigInt(255);

  /** The email. */
  private readonly _email: string;

  /**
   * Instantiates a new User.
   *
   * @param email the email
   * @param id the id
   * @param userType the user type
   * @param firstName the first name
   * @param lastName the last name
   * @param phoneNumber the phone number
   * @param created the created date
   * @param updated the updated date
   * @param enabled the enabled flag
   */
  constructor(
    email: string,
    id?: string,
    firstName?: string,
    lastName?: string,
    phoneNumber?: PhoneNumber,
    created?: Date,
    updated?: Date,
    enabled?: boolean) {
    super(id, created, updated, enabled);
    this._email = email;
    this._firstName = firstName !== undefined ? firstName : null;
    this._lastName = lastName !== undefined ? lastName : null;
    this._phoneNumber = phoneNumber !== undefined ? phoneNumber : null;
  }

  /** The first name. */
  private _firstName: string | null;

  /**
   * Gets the first name.
   *
   * @return the first name
   */
  public get firstName(): string | null {
    return this._firstName;
  }

  /**
   * Sets the first name.
   *
   * @param firstName the first name
   */
  public set firstName(firstName: string | null) {
    this._firstName = firstName;
  }

  /** The last name. */
  private _lastName: string | null;

  /**
   * Gets the last name.
   *
   * @return the last name
   */
  public get lastName(): string | null {
    return this._lastName;
  }

  /**
   * Sets the last name.
   *
   * @param lastName the last name
   */
  public set lastName(lastName: string | null) {
    this._lastName = lastName;
  }

  /** The phone number. */
  private _phoneNumber: PhoneNumber | null;

  /**
   * Gets the phone number.
   *
   * @return the phone number
   */
  public get phoneNumber(): PhoneNumber | null {
    return this._phoneNumber;
  }

  /**
   * Sets the phone number.
   *
   * @param phoneNumber the phone number
   */
  public set phoneNumber(phoneNumber: PhoneNumber | null) {
    this._phoneNumber = phoneNumber;
  }

  /**
   * Gets a user's full name (firstName + " " + lastName) if available or returns their email
   * address.
   *
   * @return the user's name or email address
   */
  public get name(): string {
    return this.hasName() ? this._firstName + " " + this._lastName : this._email;
  }

  /**
   * Gets the email.
   *
   * @return the email
   */
  public get email(): string {
    return this._email;
  }

  /**
   * Checks to see if a user has a first and last name.
   *
   * @return true, if a User has a first and last name
   */
  public hasName(): boolean {
    return this._firstName !== undefined
      && this._lastName !== undefined
      && this._firstName !== null
      && this._lastName !== null
      && this._firstName.trim().length > 0
      && this._lastName.trim().length > 0;
  }

}
