<div>
  <!-- loading -->
  <ng-container #loading *ngIf="isLoading">
    <div class="w-full my-4 flex justify-center">
      <div
        class="text-indigo-600 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
        <span
          class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
      </div>
    </div>
  </ng-container>

  <!-- NOT loading && empty-->
  <ng-container *ngIf="!this.isLoading && this.isEmpty">
    <H2>This is an empty list - we need to figure this shit out!!!</H2>
  </ng-container>

  <!-- NOT loading && NOT empty -->
  <ng-container *ngIf="!isLoading && !isEmpty">
    <ul role="list" class="grid grid-cols-1 gap-6 max-[425px]:grid-cols-1 grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
      <li 
        *ngFor="let item of page?.content"
        class="w-full h-[542px] md:h-[428px] lg:h-[428px] pb-3 rounded-lg shadow-md"
        (click)="cardDefinition.cardAction(item)"
      >
        <!-- image section -->
        <div class="w-full h-[85%] md:h-[81%] mb-2 rounded-t-lg bg-gray-300 relative">
            <img *ngIf="cardDefinition.image" class="w-full h-full rounded-t-lg" [src]="cardDefinition.image(item)">

            <!-- edit button -->
            <!-- <io-provetech-button *ngIf="cardDefinition.action" class="absolute top-4 right-4 cursor-pointer" styles="w-min border border-gray-700 p-1 rounded" (click)="cardDefinition.action(item)">
              <io-provetech-icon icon="pencil"></io-provetech-icon>
            </io-provetech-button> -->
        </div>

        <div class="mt-4 flex gap-x-[6px] px-4">
          <div [innerHTML]="cardDefinition.data(item) | safe"></div>
        </div>
      </li>
    </ul>
    
  </ng-container>
</div>