import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  /**
   * Create new [key, value] pair in local storage.
   * 
   * @param key - localstorage key.
   * @param value - localstorage value
   */
  setItem<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Get item value from local storage by passed item.
   * 
   * @param key - localstorage key.
   * @returns generic value. If value doesn't exist returns empty object.
   */
  getItem<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key) || '{}') as T;
  }

  /**
   * Remove item from local storage by passed key. 
   * 
   * @param key - localstorage key.
   */
  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  /** Clear local storage. */
  clear(): void {
    localStorage.clear();
  }
}
