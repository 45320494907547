import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// components
import { SignInComponent } from './sign-in/sign-in.component';
import { AppleLogoSvg } from './svg-logos/apple-logo.component';
import { GoogleLogoSvg } from './svg-logos/google-logo.component';
import { LogoSvg } from './svg-logos/logo.component';
import { InputComponent } from './input/input.component';
import { ButtonComponent } from './button/button.component';
import { ShellComponent } from './shell/shell.component';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { PaginationComponent } from './pagination/pagination.component';
import { WarningModalComponent } from './dialog/warning-modal/warning-modal.component';
import { SuccessModalComponent } from './dialog/success-modal/success-modal.component';
import { PageHeadingComponent } from './page-heading/page-heading.component';
import { TableComponent } from './table/table.component';
import { SafeHtml } from './_pipes/safe-html.pipe';
import { PhoneNumberFormatterDirective } from './_directives/phone-formatter.directive';
import { PhoneNumberInputComponent } from './phone-number-input/phone-number-input.component';
import { AddressInputComponent } from './address-input/address-input.component';
import { FormComponent } from './form/form.component';
import { TableComponentGeneric } from './table-generic/table.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { InfoToastComponent } from './toast/info-toast/info-toast.component';
import { SuccessToastComponent } from './toast/success-toast/success-toast.component';
import { ErrorToastComponent } from './toast/error-toast/error-toast.component';
import { GridListComponent } from './grid-list/grid-list.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { FileInputComponent } from './file-input/file-input.component';
import { IconComponent } from './icon/icon.component';
import { GenericDialogComponent } from './dialog/generic-dialog/generic-dialog.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
  declarations: [
    SignInComponent,
    AppleLogoSvg,
    GoogleLogoSvg,
    LogoSvg,
    InputComponent,
    ButtonComponent,
    ShellComponent,
    ClickOutsideDirective,
    PaginationComponent,
    WarningModalComponent,
    SuccessModalComponent,
    PageHeadingComponent,
    TableComponent,
    SafeHtml,
    PhoneNumberFormatterDirective,
    PhoneNumberInputComponent,
    AddressInputComponent,
    FormComponent,
    FormComponent,
    TableComponentGeneric,
    ErrorPageComponent,
    InfoToastComponent,
    SuccessToastComponent,
    ErrorToastComponent,
    GridListComponent,
    CustomSelectComponent,
    FileInputComponent,
    IconComponent,
    GenericDialogComponent,
    DynamicFormComponent,
    FilePreviewComponent,
    FileUploaderComponent,
  ],
  exports: [
    SignInComponent,
    AppleLogoSvg,
    GoogleLogoSvg,
    LogoSvg,
    InputComponent,
    ButtonComponent,
    ShellComponent,
    ClickOutsideDirective,
    PaginationComponent,
    WarningModalComponent,
    SuccessModalComponent,
    PageHeadingComponent,
    TableComponent,
    PhoneNumberInputComponent,
    AddressInputComponent,
    FormComponent,
    TableComponentGeneric,
    ErrorPageComponent,
    GridListComponent,
    CustomSelectComponent,
    SafeHtml,
    FileUploaderComponent,
    IconComponent,
    DynamicFormComponent,
  ],
})
export class SharedUiModule {}
