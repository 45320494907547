import {Key as KeyClass} from '@nuov.io/model';
export class Key extends KeyClass {

  /** The constant DATA. */
  public static readonly DATA: string = "data";

  /** The constant FILE_NAME. */
  public static readonly  FILE_NAME: string = "fileName";

  /** The constant IMAGE. */
  public static readonly  IMAGE: string = "image";

  /** The constant LATITUDE. */
  public static readonly  LATITUDE: string = "latitude";

  /** The constant LONGITUDE. */
  public static readonly  LONGITUDE: string = "longitude";

  /** The constant MANUFACTURER_ID. */
  public static readonly  MANUFACTURER_ID: string = "manufacturerId";

  /** The constant PRECISION. */
  public static readonly  PRECISION: string = "precision";

  /** The constant PRODUCT_ID. */
  public static readonly  PRODUCT_ID: string = "productId";

  /** The constant PRODUCTION_ID. */
  public static readonly  PRODUCTION_ID: string = "productionId";

  /** The constant TIMESTAMP. */
  public static readonly TIMESTAMP: string = "timestamp";

  /** The constant UID. */
  public static readonly UID: string = "uid";

  /** The constant UNIT. */
  public static readonly  UNIT: string = "unit";

  /** The constant UNIT_ID. */
  public static readonly  UNIT_ID: string = "unitId";

  /** The constant USER_ID. */
  public static readonly  USER_ID: string = "userId";

  /** The constant USER_UNIT_ID. */
  public static readonly  USER_UNIT_ID: string = "userUnitId";

  /** The constant YEAR. */
  public static readonly  YEAR: string = "year";
}
