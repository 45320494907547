import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'io-provetech-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent {
  @Input() fileUrl: string | null = null;
  @Output() clearEvent: EventEmitter<void> = new EventEmitter();

  clear() {
    this.clearEvent.emit();
  }
}
