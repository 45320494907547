import { TemplateRef } from '@angular/core';
import { Model } from '@nuov.io/model';
import { Noun, NumberValidator } from '@nuov.io/validator';

/** Rendering types enum */
export enum RenderingType {
  RAW = 'RAW',
  HTML = 'HTML',
  TEMPLATE = 'TEMPLATE',
}

/**
 * Custom table template interface
 *
 * @type-param T - The model type
 */
export interface TemplateType<T> {
  templateRef: TemplateRef<T>;
  context: any;
}

/** Column styling interface */
export interface ColumnStyling {
  alignment?: 'left' | 'center' | 'right'; // the alignment
  size?: 'small' | 'medium' | 'relative'; // the size
  hides?: boolean; // the hides flag
  displayHeading?: boolean; // the display heading flag
  headingCss?: string; // the heading css
  dataCss?: string; // the data css
}

/**
 * The class TableDefinition.
 *
 * @type-param T - The model type
 */
export class TableDefinition<T extends Model> {
  /**
   * The constructor.
   *
   * @param columnDefinitions the array of column definitions.
   */
  constructor(public readonly columnDefinitions: ColumnDefinition<T>[]) {}

  /**
   * Gets the table heading css.
   *
   * @param index the index
   * @returns the table heading css
   */
  public tableHeadingCss(index: number): string {
    return `${index === 0 ? 'isolate ' : ''} ${
      this.columnDefinition(index).headingCss
    }`;
  }

  /**
   * Gets the table data css.
   *
   * @param index the index
   * @returns the table data css
   */
  public tableDataCss(index: number): string {
    return this.columnDefinition(index).dataCss;
  }

  /**
   * Gets the column definition.
   *
   * @param index the index
   * @returns the column definition
   * @private
   */
  private columnDefinition(index: number): ColumnDefinition<T> {
    NumberValidator.the(Noun.ARGUMENT, 'index', index)
      .minimum(0)
      .maximum(this.columnDefinitions.length - 1)
      .validate();
    return this.columnDefinitions[index];
  }
}

/**
 * The class ColumnDefinition.
 *
 * @type-param T - The model type
 */
export class ColumnDefinition<T extends Model> {
  /** The column css. */
  private readonly _columnCss: string = 'px-3 py-4 text-sm ';

  private readonly _styling: ColumnStyling = {};

  /**
   * The constructor.
   *
   * @param heading the heading
   * @param renderingType
   * @param data the data
   * @param action the action
   * @param styling column styling
   */
  constructor(
    public heading: string,
    public renderingType: RenderingType,
    public data: (model: T) => string | TemplateType<T>,
    public action?: (model: T) => void,
    public styling?: ColumnStyling
  ) {
    this._styling = styling ?? {
      alignment: 'left',
      size: 'relative',
      hides: false,
      displayHeading: true,
    };

    // action
    this.action = action === undefined ? undefined : action;
  }

  public getDataAsTemplate(model: T): TemplateType<T> | null {
    const template = this.data(model);
    if (typeof template !== 'string') {
      return {
        templateRef: template.templateRef,
        context: template.context,
      } as TemplateType<T>;
    }

    return null;
  }

  /**
   * Gets the display heading flag.
   *
   * @returns the display heading flag
   */
  public get displayHeading(): boolean {
    return this._styling.displayHeading ?? true;
  }

  /**
   * Gets the heading css.
   *
   * @returns the heading css
   */
  public get headingCss(): string {
    return `relative ${this.columnCss} font-semibold text-gray-900 ${
      this._styling.headingCss !== undefined
        ? ' ' + this._styling.headingCss
        : ''
    }`;
  }

  /**
   * Gets the data css.
   *
   * @returns the data css
   */
  public get dataCss(): string {
    return `relative ${this.columnCss} ${
      this._styling.dataCss !== undefined ? this._styling.dataCss : ''
    }`;
  }

  /**
   * Gets the column css.
   *
   * @returns the column css
   * @private
   */
  private get columnCss(): string {
    let css = ' ';

    // hides
    if (this._styling.hides) {
      css += 'hidden ';
    }

    // size
    if (this._styling.size === 'small') {
      css += 'sm:table-cell ';
    } else if (this._styling.size === 'medium') {
      css += 'md:table-cell ';
    } else {
      css += 'relative ';
    }

    // alignment
    css += 'text-' + this._styling.alignment + ' ';

    // trim and return
    return (css + this._columnCss).trim();
  }
}
