import {Model} from "@nuov.io/model";
import {HttpParams} from "@angular/common/http";
import {AbstractFilter} from "./abstract-filter.model";
import {BehaviorSubject} from "rxjs";
import {Noun, Sentence, Superlative} from "@nuov.io/sentence";

/**
 * The class FilterSubject.
 *
 * @type-param T - The model type
 */
export class FilterSubject<T extends Model> extends BehaviorSubject<HttpParams> {

  /** The filters. */
  private _filters: AbstractFilter<T>[] = [];

  /** The change handler. */
  private readonly _changeHandler: () => void;

  /**
   * The constructor.
   */
  constructor() {

    super(new HttpParams());

    // change handler
    this._changeHandler = () => {
      this.push();
    }
  }

  /**
   * Gets the change handler.
   *
   * @returns the change handler
   */
  public get changeHandler(): () => void {
    return this._changeHandler;
  }

  /**
   * Adds a filter.
   *
   * @param filter the filter
   */
  public addFilter(filter: AbstractFilter<T>): void {
    this._filters.push(filter);
  }

  public clearFilters(): void {
    this._filters = [];
    this.next(new HttpParams());
  }

  /**
   * Pushes to the http params.
   */
  private push(): void {

    // merge the http parameters
    let unsortedParams = new HttpParams({});
    for (let i = 0; i < this._filters.length; i++) {
      for (let key of this._filters[i].httpParams.keys()) {

        // validate
        if (unsortedParams.has(key)) {
          throw new Error(Sentence.the(Noun.KEY, key).already(Superlative.EXISTS).period());
        }

        // assign
        if (this._filters[i].httpParams.get(key) !== null) {
          unsortedParams = unsortedParams.set(key, this._filters[i].httpParams.get(key)!);
        }
      }
    }

    // sort
    let sortedParams = new HttpParams({});
    const keys = unsortedParams.keys().sort();
    for (let key of keys) {
      sortedParams = sortedParams.set(key, unsortedParams.get(key)!);
    }

    // publish the sortedParams
    this.next(sortedParams);
  }
}
