import {AbstractModelUpdated} from '@nuov.io/model';
import {UnitTag} from "./UnitTag";

/** The Class Unit. */
export class Unit extends AbstractModelUpdated {

  /** The production id. */
  private readonly _productionId:string;

  /** The list of UnitTags. */
  private _unitTags: UnitTag[];

  /**
   * Instantiates a new Unit.
   *
   * @param productionId the production id
   * @param id the id
   * @param unitTags the unit tags
   * @param created the created date
   * @param updated the updated date
   * @param enabled the enabled flag
   */
  constructor(
      productionId: string,
      id?: string,
      unitTags?: UnitTag[],
      created?:Date,
      updated?:Date,
      enabled?:boolean) {
    super(id, created, updated, enabled);
    this._productionId = productionId;
    this._unitTags =  unitTags !== undefined && unitTags.length > 0 ? unitTags : [];
  }

  /**
   * Gets the production id.
   *
   * @return the production id
   */
  public get productionId(): string {
    return this._productionId;
  }

  /**
   * Gets the array of unit tags.
   *
   * @return the array of unit tags
   */
  public get unitTags(): UnitTag[] {
    return this._unitTags;
  }

  /**
   * Sets the array of unit tags.
   *
   * @param unitTags the array of unit tags
   */
  public set unitTags(unitTags: UnitTag[]) {
      this._unitTags = unitTags;
  }
}
