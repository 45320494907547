import {AbstractModelUpdated} from '@nuov.io/model';
import {StringValidator} from "@nuov.io/validator";
import {Noun} from "@nuov.io/sentence";

/** The Class Manufacturer. */
export class Manufacturer extends AbstractModelUpdated {

  /** The constant MAXIMUM_NAME_LENGTH. */
  public static readonly MAXIMUM_NAME_LENGTH: bigint = BigInt(50);

  /**
   * Instantiates a new Manufacturer.
   *
   * @param name the name
   * @param id the id
   * @param created the created date
   * @param updated the updated date
   * @param enabled the enabled flag
   */
  constructor(
    name: string,
    id?: string | null,
    created?: Date,
    updated?: Date,
    enabled?: boolean) {
    super(id, created, updated, enabled);
    this._name = name;
  }

  /** The name. */
  private _name: string;

  /**
   * Gets the name.
   *
   * @return the name
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Sets the name.
   *
   * @param name the name
   */
  public set name(name: string) {
    name = name.trim();
    StringValidator.the(Noun.ARGUMENTS, "name", name).maximumLength(Manufacturer.MAXIMUM_NAME_LENGTH).validate();
    this._name = name;
  }
}
