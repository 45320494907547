// Routes constants
export const RoutesConstants = {
  admin: {
    mainRoute: 'admin',
    login: { route: 'login', title: 'Login' },
    manufacturers: { route: 'manufacturers', title: 'Manufacturers' },
    assembly: { route: 'assembly', title: 'Assembly' },
    products: { route: 'manufacturers', title: 'Products' },
    manufacturer_form: {
      route: 'manufacturer-form',
      title: 'Manufacturer Form',
    },
    manufacturer_cmp: { route: 'manufacturers', title: 'Manufacturer cmp' },
  },
  manufacturer: {
    mainRoute: 'manufacturer',
    login: { route: 'login', title: 'Login' },
    products: { route: 'products', title: 'Products' },
    users: { route: 'users', title: 'Users' },
  },
  user: {},
  common: {
    error: {
      defaultError: { route: 'defaultError' },
    },
  },
};
