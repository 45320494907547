import { OverlayRef } from '@angular/cdk/overlay';

/** Toast reference class. */
export class ToastRef {
  /**
   * The Comstructor.
   * 
   * @param overlay Overlay reference
   */
  constructor(private readonly overlay: OverlayRef) { }

  /** Close method. */
  close() {
    this.overlay.dispose();
  }
}