import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { TOAST_CONFIG_TOKEN, ToastConfig } from './toast-config';
import { ToastRef } from './toast-ref';
import { toastAnimations, ToastAnimationState } from './toast-animation';

@Component({
  selector: 'app-toast',
  template: ``,
  animations: [toastAnimations.fadeToast],
})
export class ToastComponent implements OnInit, OnDestroy {
  /** Toast defualt animation state. */
  public animationState: ToastAnimationState = 'default';

  /** Interval ID. */
  private intervalId!: number;

  /**
   * The constructor.
   * 
   * @param toastRef Toast reference
   * @param toastConfig Toast config
   */
  constructor(
    readonly toastRef: ToastRef,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
    ) {}

  /** On init hook. */
  ngOnInit() {
    this.intervalId = setTimeout(() => this.animationState = 'closing', 5000);
  }

  /** On destroy hook. */
  ngOnDestroy() {
    clearTimeout(this.intervalId);
  }

  /** Close method. */
  public close() {
    this.toastRef.close();
  }

  /** Animation finish method. */
  public onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }
}