import {AbstractModelUpdated} from '@nuov.io/model';
import {BigIntValidator, StringValidator} from "@nuov.io/validator";
import {Noun} from "@nuov.io/sentence";

/** The Class Production. */
export class Production extends AbstractModelUpdated {

  /** The constant MAXIMUM_NAME_LENGTH. */
  public static readonly MAXIMUM_NAME_LENGTH: bigint = BigInt(50);

  /** The constant MINIMUM_YEAR. */
  public static readonly MINIMUM_YEAR: bigint = BigInt(1900);

  /** The constant MAXIMUM_YEAR. */
  public static readonly MAXIMUM_YEAR: bigint = BigInt(2100);

  /** The product id. */
  private readonly _productId:string;

  /** The name. */
  private _name: string;

  /** The number of units. */
  private _numberOfUnits: number;

  /** The year. */
  private _year!: bigint;

  /**
   * Instantiates a new Production.
   *
   * @param productId the product id
   * @param name the name
   * @param numberOfUnits the number of units
   * @param year the year
   * @param id the id
   * @param created the created date
   * @param updated the updated date
   * @param enabled the enabled flag
   */
  constructor(
      productId: string,
      name: string,
      numberOfUnits: number,
      year?: bigint,
      id?: string,
      created?: Date,
      updated?: Date,
      enabled?: boolean) {
    super(id, created, updated, enabled);
    this._productId = productId;
    this._name = name;
    this._numberOfUnits = numberOfUnits;
    if (year) {
      this._year = year;
    }
  }

  /**
   * Gets the product id.
   *
   * @return the product id
   */
  public get productId():string {
    return this._productId;
  }

  /**
   * Gets the name.
   *
   * @return the name
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Gets the name.
   *
   * @return the numberOfUnits
   */
  public get numberOfUnits(): number {
    return this._numberOfUnits;
  }

  /**
   * Sets the name.
   *
   * @param name the name
   */
  public set name(name: string) {
    name = name.trim();
    StringValidator.the(Noun.ARGUMENTS, "name", name).maximumLength(Production.MAXIMUM_NAME_LENGTH).validate();
    this._name = name;
  }

/**
   * Gets the year.
   *
   * @return the year
   */
  public get year():bigint {
    return this._year;
  }

  /**
   * Sets the year.
   *
   * @param year the year
   */
  public set year(year: bigint) {
    BigIntValidator.the(Noun.ARGUMENTS, "year", year).minimum(Production.MINIMUM_YEAR).maximum(Production.MAXIMUM_YEAR).validate();
    this._year = year;
  }
}
