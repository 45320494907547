import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

import { DialogRef } from '../dialog-ref';
import { DIALOG_DATA, DialogConfig } from '../dialog.model';
import { ToastService } from '../../toast';
import { FormGroup } from '@angular/forms';
import { DIALOG_FORM_TOKEN } from '../../dynamic-form/dynamic-form.component';

@Component({
  selector: 'io-provetech-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
})
export class GenericDialogComponent implements OnInit, OnDestroy {
  private readonly myInjector = inject(Injector);

  /** Component reference. */
  public componentRef!: ComponentRef<any>;

  /** View container reference. */
  @ViewChild('vcr', { static: true, read: ViewContainerRef })
  private vcr!: ViewContainerRef;

  /**
   * The constructor.
   *
   * @param dialogRef dialog reference
   * @param config dialog config
   * @param cdr change detector reference
   */
  constructor(
    private dialogRef: DialogRef,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    @Inject(DIALOG_DATA) public config: DialogConfig
  ) {}

  ngOnInit(): void {
    this.vcr.clear();
    if (this.config.component) {
      const injector = Injector.create({
        providers: [
          {
            provide: DIALOG_FORM_TOKEN,
            useValue: { ...this.config.injectedValue, dialogRef: this.dialogRef }
          },
        ],
        parent: this.myInjector,
      });
      this.componentRef = this.vcr.createComponent(this.config.component, {
        injector: injector,
      });
    }
    this.cdr.detectChanges();
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.dialogRef.close(true);
  }

  ngOnDestroy(): void {
    this.componentRef.destroy();
  }
}
