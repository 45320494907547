import { ErrorHandler, Inject, Injectable } from '@angular/core';

import Bugsnag from "@bugsnag/js";

import { Event } from "../event-bus/event.class";
import { EventBusService} from "../event-bus/event-bus.service";
import { LoggingService } from "../logging/logging.service";
import { API_TOKEN, ERROR_HANDLER_COMPLETED, ERROR_HANDLER_INITIATED } from "./error-handler.constants";

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  /**
   * The constructor.
   */
  constructor(
    private loggingService: LoggingService, 
    private busEvent: EventBusService, 
    @Inject(API_TOKEN) public apiToken: string) {

    // log instantiation
    this.loggingService.informational(this.constructor.name + ' instantiated.');

    // instantiate bugsnag
    Bugsnag.start(this.apiToken);
  }

  handleError(error: any): void {
    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key: any, value: object | null) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };

    // log the error
    this.loggingService.error(`ErrorHandlerService.handleError(${JSON.stringify(error, getCircularReplacer())})`);

    // event (pre-report)
    let event: Event<Error> = new Event<Error>(ERROR_HANDLER_INITIATED, error);
    this.busEvent.publish(event);

    // bugsnag
    Bugsnag.notify(error)

    // event post report
    event = new Event<Error>(ERROR_HANDLER_COMPLETED, error);
    this.busEvent.publish(event);

  }
}