// TODO check icons
// svg icons

export const editIcon = `
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5118 1.48816L13.2189 0.781049V0.781049L12.5118 1.48816ZM3.33333 13.0237V14.0237C3.59855 14.0237 3.8529 13.9183 4.04044 13.7308L3.33333 13.0237ZM1 13.0237H0C0 13.5759 0.447715 14.0237 1 14.0237L1 13.0237ZM1 10.6429L0.292893 9.93583C0.105357 10.1234 0 10.3777 0 10.6429H1ZM10.8619 2.19526C11.1222 1.93491 11.5444 1.93491 11.8047 2.19526L13.2189 0.781049C12.1775 -0.26035 10.4891 -0.26035 9.44768 0.781049L10.8619 2.19526ZM11.8047 2.19526C12.0651 2.45561 12.0651 2.87772 11.8047 3.13807L13.2189 4.55228C14.2603 3.51089 14.2603 1.82245 13.2189 0.781049L11.8047 2.19526ZM11.8047 3.13807L2.62623 12.3165L4.04044 13.7308L13.2189 4.55228L11.8047 3.13807ZM3.33333 12.0237H1V14.0237H3.33333V12.0237ZM9.44768 0.781049L0.292893 9.93583L1.70711 11.35L10.8619 2.19526L9.44768 0.781049ZM0 10.6429V13.0237H2V10.6429H0ZM8.44768 3.19526L10.8047 5.55228L12.2189 4.13807L9.86189 1.78105L8.44768 3.19526Z" fill="#374151"/>
  </svg>
`;

export const viewIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 !inline">
    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>
`;

export const trashIcon = `
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.667 3.66667L11.0888 11.7617C11.0389 12.4594 10.4584 13 9.75883 13H4.24182C3.5423 13 2.96171 12.4594 2.91187 11.7617L2.33366 3.66667M5.66699 6.33333V10.3333M8.33366 6.33333V10.3333M9.00033 3.66667V1.66667C9.00033 1.29848 8.70185 1 8.33366 1H5.66699C5.2988 1 5.00033 1.29848 5.00033 1.66667V3.66667M1.66699 3.66667H12.3337" stroke="#374151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

export const checkIcon = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 13L9 17L19 7" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

export const xMark = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 !inline">
    <path d="M6 18L18 6M6 6L18 18" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

export const addIcon = `
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3337 8.33366V12.3337M8.33366 10.3337H12.3337M3.00033 5.66699H4.33366C5.07004 5.66699 5.66699 5.07004 5.66699 4.33366V3.00033C5.66699 2.26395 5.07004 1.66699 4.33366 1.66699H3.00033C2.26395 1.66699 1.66699 2.26395 1.66699 3.00033V4.33366C1.66699 5.07004 2.26395 5.66699 3.00033 5.66699ZM9.66699 5.66699H11.0003C11.7367 5.66699 12.3337 5.07004 12.3337 4.33366V3.00033C12.3337 2.26395 11.7367 1.66699 11.0003 1.66699H9.66699C8.93061 1.66699 8.33366 2.26395 8.33366 3.00033V4.33366C8.33366 5.07004 8.93061 5.66699 9.66699 5.66699ZM3.00033 12.3337H4.33366C5.07004 12.3337 5.66699 11.7367 5.66699 11.0003V9.66699C5.66699 8.93061 5.07004 8.33366 4.33366 8.33366H3.00033C2.26395 8.33366 1.66699 8.93061 1.66699 9.66699V11.0003C1.66699 11.7367 2.26395 12.3337 3.00033 12.3337Z" stroke="#374151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

export const filtersIcon = `
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3V1M9 3C7.89543 3 7 3.89543 7 5C7 6.10457 7.89543 7 9 7M9 3C10.1046 3 11 3.89543 11 5C11 6.10457 10.1046 7 9 7M3 15C4.10457 15 5 14.1046 5 13C5 11.8954 4.10457 11 3 11M3 15C1.89543 15 1 14.1046 1 13C1 11.8954 1.89543 11 3 11M3 15V17M3 11V1M9 7V17M15 15C16.1046 15 17 14.1046 17 13C17 11.8954 16.1046 11 15 11M15 15C13.8954 15 13 14.1046 13 13C13 11.8954 13.8954 11 15 11M15 15V17M15 11V1" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

export const accountIcon = `
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_587_40393)">
    <circle cx="12.0064" cy="12.0073" r="11" transform="rotate(-0.0305245 12.0064 12.0073)" fill="#EFEFF0" stroke="#AFB1B6" stroke-width="2"/>
    <circle cx="12.0063" cy="9.50737" r="3.5" transform="rotate(-0.0305245 12.0063 9.50737)" stroke="#AFB1B6" stroke-width="2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.52185 19.3595C4.593 16.7327 8.06858 15.0088 12.0076 15.0067C15.9467 15.0046 19.4241 16.7248 21.498 19.3494C21.0806 19.8882 20.6183 20.3906 20.1167 20.8508C18.4912 18.5999 15.5694 17.0048 12.0087 17.0067C8.44799 17.0086 5.52792 18.6068 3.90483 20.8595C3.40274 20.3998 2.93988 19.8979 2.52185 19.3595Z" fill="#AFB1B6"/>
    </g>
    <defs>
    <clipPath id="clip0_587_40393">
    <rect width="24" height="24" fill="white" transform="translate(0 0.0136719) rotate(-0.0305245)"/>
    </clipPath>
    </defs>
  </svg>
`;

export const redRemoveIcon = `
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.9987 18.6667C9.9987 19.0203 10.1392 19.3594 10.3892 19.6095C10.6393 19.8595 10.9784 20 11.332 20H16.6654C17.019 20 17.3581 19.8595 17.6082 19.6095C17.8582 19.3594 17.9987 19.0203 17.9987 18.6667V10.6667H9.9987V18.6667ZM11.332 12H16.6654V18.6667H11.332V12ZM16.332 8.66667L15.6654 8H12.332L11.6654 8.66667H9.33203V10H18.6654V8.66667H16.332Z" fill="#EB5757"/>
    <rect x="1" y="1" width="26" height="26" rx="7" stroke="#EB5757" stroke-width="2"/>
  </svg>
`;

export const productIcon = `
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 5L9 1L1 5M17 5L9 9M17 5V15L9 19M9 9L1 5M9 9V19M1 5V15L9 19" stroke="#111827" stroke-width="2" stroke-linejoin="round"/>
  </svg>
`;

export const productionIcon = `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 5H5.01M5 1H10C10.5119 0.999985 11.0237 1.19525 11.4142 1.58579L18.4143 8.58579C19.1953 9.36684 19.1953 10.6332 18.4143 11.4142L11.4142 18.4142C10.6332 19.1953 9.36683 19.1953 8.58579 18.4142L1.58579 11.4142C1.19526 11.0237 1 10.5118 1 10V5C1 2.79086 2.79086 1 5 1Z" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
`;

export const userAuthIcon = `
  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 17H21V15C21 13.3431 19.6569 12 18 12C17.0444 12 16.1931 12.4468 15.6438 13.1429M16 17H6M16 17V15C16 14.3438 15.8736 13.717 15.6438 13.1429M6 17H1V15C1 13.3431 2.34315 12 4 12C4.95561 12 5.80686 12.4468 6.35625 13.1429M6 17V15C6 14.3438 6.12642 13.717 6.35625 13.1429M6.35625 13.1429C7.0935 11.301 8.89482 10 11 10C13.1052 10 14.9065 11.301 15.6438 13.1429M14 4C14 5.65685 12.6569 7 11 7C9.34315 7 8 5.65685 8 4C8 2.34315 9.34315 1 11 1C12.6569 1 14 2.34315 14 4ZM20 7C20 8.10457 19.1046 9 18 9C16.8954 9 16 8.10457 16 7C16 5.89543 16.8954 5 18 5C19.1046 5 20 5.89543 20 7ZM6 7C6 8.10457 5.10457 9 4 9C2.89543 9 2 8.10457 2 7C2 5.89543 2.89543 5 4 5C5.10457 5 6 5.89543 6 7Z" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

export const addressBookIcon = `
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 2.25278V15.2528M10 2.25278C8.83211 1.47686 7.24649 1 5.5 1C3.75351 1 2.16789 1.47686 1 2.25278V15.2528C2.16789 14.4769 3.75351 14 5.5 14C7.24649 14 8.83211 14.4769 10 15.2528M10 2.25278C11.1679 1.47686 12.7535 1 14.5 1C16.2465 1 17.8321 1.47686 19 2.25278V15.2528C17.8321 14.4769 16.2465 14 14.5 14C12.7535 14 11.1679 14.4769 10 15.2528" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

export const activityIcon = `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 6V10L13 13M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

export const truckIcon = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 16V6C13 5.44772 12.5523 5 12 5H4C3.44772 5 3 5.44772 3 6V16C3 16.5523 3.44772 17 4 17H5M13 16C13 16.5523 12.5523 17 12 17H9M13 16L13 8C13 7.44772 13.4477 7 14 7H16.5858C16.851 7 17.1054 7.10536 17.2929 7.29289L20.7071 10.7071C20.8946 10.8946 21 11.149 21 11.4142V16C21 16.5523 20.5523 17 20 17H19M13 16C13 16.5523 13.4477 17 14 17H15M5 17C5 18.1046 5.89543 19 7 19C8.10457 19 9 18.1046 9 17M5 17C5 15.8954 5.89543 15 7 15C8.10457 15 9 15.8954 9 17M15 17C15 18.1046 15.8954 19 17 19C18.1046 19 19 18.1046 19 17M15 17C15 15.8954 15.8954 15 17 15C18.1046 15 19 15.8954 19 17" stroke="#111827" stroke-width="2"/>
  </svg>
`;

export const addUserIcon = `
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5V7M11 7V9M11 7H13M11 7H9M7.66667 3.66667C7.66667 5.13943 6.47276 6.33333 5 6.33333C3.52724 6.33333 2.33333 5.13943 2.33333 3.66667C2.33333 2.19391 3.52724 1 5 1C6.47276 1 7.66667 2.19391 7.66667 3.66667ZM1 12.3333C1 10.1242 2.79086 8.33333 5 8.33333C7.20914 8.33333 9 10.1242 9 12.3333V13H1V12.3333Z" stroke="#374151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;


export const calendar = `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 5V1M14 5V1M5 9H15M3 19H17C18.1046 19 19 18.1046 19 17V5C19 3.89543 18.1046 3 17 3H3C1.89543 3 1 3.89543 1 5V17C1 18.1046 1.89543 19 3 19Z" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;