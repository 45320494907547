<div>
  <!-- loading -->
  <ng-container #loading *ngIf="isLoading">
    <div class="w-full my-4 flex justify-center">
      <div
        class="text-indigo-600 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span
          class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
          >Loading...</span
        >
      </div>
    </div>
  </ng-container>

  <!-- NOT loading && empty-->
  <ng-container *ngIf="!this.isLoading && this.isEmpty">
    <H2>This is an empty list - we need to figure this shit out!!!</H2>
  </ng-container>

  <!-- NOT loading && NOT empty-->
  <ng-container *ngIf="!this.isLoading && !this.isEmpty">
    <div class="flow-root overflow-hidden">
      <div class="mx-auto max-w-mac-13 px-4 sm:px-6 lg:px-8">
        <table class="w-full text-left">
          <!-- table header -->
          <thead class="bg-white">
            <tr>
              <!-- for each table heading: -->
              <ng-container
                *ngFor="
                  let columnDefinition of this.tableDefinition
                    .columnDefinitions;
                  let i = index
                "
              >
                <th
                  scope="col"
                  class="{{ this.tableDefinition.tableHeadingCss(i) }}"
                >
                  <!-- display heading: -->
                  <ng-container *ngIf="columnDefinition.displayHeading">
                    {{ columnDefinition.heading | titlecase }}
                  </ng-container>
                  <!-- DO NOT display heading: -->
                  <ng-container *ngIf="!columnDefinition.displayHeading">
                    <span class="sr-only">{{ columnDefinition.heading }}</span>
                  </ng-container>
                  <!-- th border: first heading -->
                  <ng-container *ngIf="i === 0">
                    <div
                      class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200"
                    ></div>
                    <div
                      class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200"
                    ></div>
                  </ng-container>
                </th>
              </ng-container>
            </tr>
          </thead>
          <!-- table body -->
          <tbody>
            <ng-container *ngFor="let element of this.pageOfElements?.content">
              <tr>
                <ng-container
                  *ngFor="
                    let columnDefinition of this.tableDefinition
                      .columnDefinitions;
                    let i = index
                  "
                >
                  <td class="{{ this.tableDefinition.tableDataCss(i) }}" (click)="columnDefinition.action ? columnDefinition.action(element) : true">
                    <!-- Raw string rendering -->
                    <ng-container
                      *ngIf="columnDefinition.renderingType === renderingType.RAW"
                    >{{ columnDefinition.data(element) }}</ng-container>

                    <!-- Custom HTML rendering -->
                    <ng-container
                      *ngIf="columnDefinition.renderingType === renderingType.HTML"
                    >
                      <div [innerHTML]="columnDefinition.data(element).toString() | safe"></div>
                    </ng-container>

                    <!-- Custom template rendering -->
                    <ng-container
                      *ngIf="columnDefinition.renderingType === renderingType.TEMPLATE && columnDefinition.getDataAsTemplate(element) as template"
                    >
                      <ng-container
                        *ngTemplateOutlet="template.templateRef; context: template.context"
                      ></ng-container>
                    </ng-container>

                    <!-- tr border: first data -->
                    <ng-container *ngIf="i === 0">
                      <div
                        class="absolute bottom-0 right-full h-px w-screen bg-gray-100"
                      ></div>
                      <div
                        class="absolute bottom-0 left-0 h-px w-screen bg-gray-100"
                      ></div>
                    </ng-container>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</div>