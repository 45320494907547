import { Component, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { Location } from '@angular/common';
import { takeUntil, tap } from 'rxjs/operators';

import { IHeadingAction, IBreadcrumbInterface, ROUTER_DATA_BREADCRUMB, ROUTER_DATA_PATH } from '@io-provetech/models';
import { Subject } from 'rxjs';

/** Requires to add data (path and breadcrumb label) to routing module. */
@Component({
  selector: 'io-provetech-page-heading',
  templateUrl: './page-heading.component.html',
  styleUrls: ['./page-heading.component.scss'],
})
export class PageHeadingComponent implements OnDestroy {
  /** Title. */
  @Input() title: string | null = null;

  /** Actions list represents buttons. */
  @Input() actionsList!: IHeadingAction[];

  /** Unsubscriber subject. */
  private unsubscriber$ = new Subject<void>();

  /** BreadCrumbs list. */
  breadcrumbs: IBreadcrumbInterface[];

  /** Navigation Event. */
  navigationEvent = this.router.events;

  /** Navigation Event Start. */
  navigationEventStart = this.navigationEvent
    .pipe(takeUntil(this.unsubscriber$))
    .subscribe();

  /** Navigation Event End. */
  navigationEventEnd = this.navigationEvent
    .pipe(
      takeUntil(this.unsubscriber$),
      tap(() => {
        this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root);
      })
    )
    .subscribe();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.breadcrumbs = [];
  }

  /** Method getBreadcrumbs generates breadcrumbs list from url tree. */
  private getBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: IBreadcrumbInterface[] = []
  ): IBreadcrumbInterface[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (!child.snapshot.data.hasOwnProperty(ROUTER_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      const routeURL: string = child.snapshot.data?.[ROUTER_DATA_PATH] ?? '';

      url += `/${routeURL}`;

      const breadcrumb: IBreadcrumbInterface = {
        label: child.snapshot.data[ROUTER_DATA_BREADCRUMB],
        params: child.snapshot.params,
        queryParams: child.snapshot.queryParams,
        url,
      };
      if (breadcrumb.label) {
        breadcrumbs.push(breadcrumb);
      }

      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return [];
  }

  /** Method to go back by url tree. */
  backClicked() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.unsubscribe();
  }
}
