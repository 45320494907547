import { HttpHeaders, HttpParams } from "@angular/common/http";
import { InjectionToken } from "@angular/core";
import {Observable} from "rxjs";

import {Model} from "@nuov.io/model";
import {Page} from "@nuov.io/spring/build/esm/src/Page";
import {Params} from "@angular/router";


/** The root api url parameter for UtilsHttpService */
export const API_URL = new InjectionToken<string>('');

/** Headerlike custom interface. */
export interface HeaderLike { [name: string]: string | string[]; }

/** IRequestOptions custom interface. */
export interface IRequestOptions {
  headers?: HttpHeaders | HeaderLike;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
  body?: any;
}

/**
 * The interface HttpGetCollection.
 *
 * @type-param T - The model type
 */
export interface HttpGetCollection<T extends Model> {
  getCollection(params?: Params): Observable<Page<T>>;
}

/**
 * The interface HttpGetItem.
 *
 * @type-param T - The model type
 */
export interface HttpGetItem<T extends Model> {
  getItem(id: string, params?: any, queryParams?: unknown[], headers?: HeaderLike[]): Observable<T>;
}

/**
 * The interface HttpPostItem.
 *
 * @type-param T - The model type
 */
export interface HttpPostItem<T extends Model> {
  postItem(model: T, params?: any, options?: IRequestOptions): Observable<T>;
}

/**
 * The interface HttpPatchItem.
 *
 * @type-param T - The model type
 */
export interface HttpPatchItem<T extends Model> {
  patchItem(id: string, model: T, params?: any): Observable<T>;
}


/**
 * The interface HttpPutItem.
 *
 * @type-param T - The model type
 */
export interface HttpPutItem<T extends Model> {
  putItem(id: string, model: T, params?: any): Observable<T>;
}

/**
 * The interface HttpDeleteItem.
 *
 * @type-param T - The model type
 */
export interface HttpDeleteItem<T extends Model> {
  deleteItem(id: string, params?: any, model?: T): Observable<any>;
}


export const MANUFACTURER_ID = 'manufacturer_id';
export const PRODUCT_ID = 'product_id';
export const PRODUCTION_ID = 'production_id';
