import {AbstractModelUpdated} from '@nuov.io/model';
import {Noun} from "@nuov.io/sentence";
import {NumberValidator, UuidValidator} from "@nuov.io/validator";

/** The Class Inspection. */
export class Inspection extends AbstractModelUpdated {

  /** The constant MINIMUM_LATITUDE. */
  public static readonly MINIMUM_LATITUDE: number = -90;

  /** The constant MAXIMUM_LATITUDE. */
  public static  readonly MAXIMUM_LATITUDE: number = 90;

  /** The constant MINIMUM_LONGITUDE. */
  public static  readonly MINIMUM_LONGITUDE: number = -180;

  /** The constant MAXIMUM_LONGITUDE. */
  public static  readonly MAXIMUM_LONGITUDE: number = 180;

  /** The user id. */
  private readonly _userId: string | null;

  /** The unit id. */
  private readonly _unitId: string | null;

  /** The latitude. */
  private readonly _latitude: number;

  /** The longitude. */
  private readonly _longitude: number;

  /** The timestamp. */
  private readonly _timestamp: Date;

  /**
   * Instantiates a new Inspection.
   *
   * @param latitude the latitude
   * @param longitude the longitude
   * @param id the id
   * @param userId the user id
   * @param unitId the unit id
   * @param timestamp the timestamp
   * @param created the created date
   * @param updated the updated date
   * @param enabled the enabled flag
   */
  constructor(
      latitude: number,
  longitude: number,
      id?: string,
      userId?:string | null,
      unitId?:string | null,
      timestamp?: Date,
      created?: Date | null,
      updated?: Date | null,
      enabled?: boolean) {
    super(id, created, updated, enabled);

    // user id
    if (userId !== undefined) {
      UuidValidator.the(Noun.ARGUMENT, "userId", userId).allowNull().validate();
      this._userId = userId;
    } else {
      this._userId = null;
    }

    // unit id
    if (unitId !== undefined) {
      UuidValidator.the(Noun.ARGUMENT, "unitId", unitId).allowNull().validate();
      this._unitId = unitId;
    } else {
      this._unitId = null;
    }

    // latitude
    NumberValidator.the(Noun.ARGUMENT, "latitude", latitude).minimum(Inspection.MINIMUM_LATITUDE).maximum(Inspection.MAXIMUM_LATITUDE).validate();
    this._latitude = latitude;

    // longitude
    NumberValidator.the(Noun.ARGUMENT, "longitude", longitude).minimum(Inspection.MINIMUM_LONGITUDE).maximum(Inspection.MAXIMUM_LONGITUDE).validate();
    this._longitude = longitude;

    // timestamp
    this._timestamp = timestamp !== undefined ? timestamp : new Date();
  }

  /**
   * Gets the user id.
   *
   * @return the user id
   */
  public get userId(): string | null {
    return this._userId;
  }

  /**
   * Gets the unit id.
   *
   * @return the unit id
   */
  public get unitId(): string | null {
    return this._unitId;
  }

  /**
   * Gets the latitude.
   *
   * @return the latitude
   */
  public get latitude(): number {
    return this._latitude;
  }

  /**
   * Gets the longitude.
   *
   * @return the longitude
   */
  public get longitude(): number {
    return this._longitude;
  }

  /**
   * Gets the timestamp.
   *
   * @return the timestamp
   */
  public get timestamp(): Date {
    return this._timestamp;
  }
}
