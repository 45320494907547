import { InjectionToken } from "@angular/core";

/** The constant ERROR_HANDLER_INITIATED. */
export const ERROR_HANDLER_INITIATED: string = 'ErrorHandlerInitiated';

/** The constant ERROR_HANDLER_COMPLETED. */
export const ERROR_HANDLER_COMPLETED: string = 'ErrorHandlerCompleted';

/** The ApiKey parameter for ErrorHandlerService */
export const API_TOKEN = new InjectionToken<string>('');
