import { InjectionToken } from "@angular/core";

/** The KEEP_ALIVE_TOKEN for pinging server. */
export const KEEP_ALIVE_TOKEN = new InjectionToken<boolean>('');

/** The HEALTH_CHECK_URL for pinging server. */
export const HEALTH_CHECK_URL = new InjectionToken<string>('');

/** defaultWakeUpCount var is set to retry http operator. */
export const defaultWakeUpCount = 15;

/** Var for wake up call interval 1sec. */
export const wakeUpInterval = 1 * 1000;

/** Var for keep alive server interval 45sec. */
export const keepAliveInterval = 45 * 1000;
