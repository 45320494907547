<div
  class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
  *ngIf="page"
>
  <!-- small screen -->
  <div class="flex flex-1 justify-between sm:hidden">
    <!-- previous -->
    <a
      (click)="this.goToPreviousPage()"
      [ngClass]="{ '!cursor-not-allowed': this.page.first }"
      class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
    >Previous</a
    >
    <!-- next -->
    <a
      (click)="this.goToNextPage()"
      [ngClass]="{ '!cursor-not-allowed': this.page.last }"
      class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
    >Next</a
    >
  </div>
  <!-- normal screen -->
  <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
    <!-- pagination summary -->
    <div>
      <p class="text-sm text-gray-700">
        Showing
        <span class="font-medium">{{ ((this.page.number * this.size) + 1) }}</span>
        to
        <span class="font-medium">{{ (this.page.number * this.size) + this.page.numberOfElements }}</span>
        of
        <span class="font-medium">{{ this.page.totalElements }}</span>
        results
      </p>
    </div>
    <!-- pagination -->
    <div>
      <nav
        aria-label="Pagination"
        class="isolate inline-flex -space-x-px rounded-md shadow-sm"
      >
        <!-- previous -->
        <a
          (click)="this.goToPreviousPage()"
          [ngClass]="{ '!cursor-not-allowed': this.page.first }"
          class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer"
        >
          <span class="sr-only">Previous</span>
          <svg
            aria-hidden="true"
            class="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              clip-rule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              fill-rule="evenodd"
            />
          </svg>
        </a>
        <!-- numbers: [2] etc -->
        <ng-template [ngForOf]="this.pageLabels" let-pageLabel ngFor>
          <!-- separator : add hidden to this so that it disappears on smaller screens-->
          <span *ngIf="(this.isSeparator(pageLabel))"
                class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
          <!-- current page -->
          <a *ngIf="(this.isCurrent(pageLabel))"
             [ngClass]="{ '!cursor-not-allowed': true }"
             aria-current="page"
             class="{{ this.getCss(pageLabel-1) }}">{{ pageLabel }}</a>
          <!-- NOT current page -->
          <a (click)="this.goToPage(pageLabel)"
             *ngIf="(!this.isSeparator(pageLabel) && !this.isCurrent(pageLabel))"
             class="{{ this.getCss(pageLabel-1) }}">{{ pageLabel }}</a>
        </ng-template>
        <!-- next -->
        <a
          (click)="this.goToNextPage()"
          [ngClass]="{ '!cursor-not-allowed': this.page.last }"
          class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer"
        >
          <span class="sr-only">Next</span>
          <svg
            aria-hidden="true"
            class="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              clip-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              fill-rule="evenodd"
            />
          </svg>
        </a>
      </nav>
    </div>
  </div>
</div>
