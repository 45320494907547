import { Component, Injector, Input } from '@angular/core';

import {RenderingType, TableDefinition,} from './table.model';
import {Model} from "@nuov.io/model";
import {Page} from "@nuov.io/spring/build/esm/src/Page";

@Component({
  selector: 'io-provetech-table-generic',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponentGeneric<T extends Model> {
  /** Rendering type */
  renderingType = RenderingType;

  /** The table definition. */
  @Input() tableDefinition!: TableDefinition<T>;

  /** Table page of elements. */
  @Input() pageOfElements!: Page<T> | null;

  public get isLoading(): boolean {
    return this.pageOfElements === null;
  }

  public get isEmpty(): boolean {
    return (this.pageOfElements !== null && this.pageOfElements.numberOfElements === 0);
  }
}
