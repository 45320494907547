import {AbstractModelUpdated} from '@nuov.io/model';
import {NfcIso} from "./NfcIso";
import {Noun} from "@nuov.io/sentence";
import {StringValidator, UuidValidator} from "@nuov.io/validator";

/** The Class Nfc. */
export class Nfc extends AbstractModelUpdated {

  /** The constant MINIMUM_UID_LENGTH. */
  public static readonly MINIMUM_UID_LENGTH: bigint = BigInt(20);

  /** The constant MAXIMUM_UID_LENGTH. */
  public static  readonly MAXIMUM_UID_LENGTH: bigint = BigInt(20);

  /** The constant MINIMUM_DATA_LENGTH. */
  public static  readonly MINIMUM_DATA_LENGTH: bigint = BigInt(20);

  /** The constant MAXIMUM_DATA_LENGTH. */
  public static  readonly MAXIMUM_DATA_LENGTH: bigint = BigInt(128);

  /** The unit id. */
  private _unitId: string | null;

  /** The iso. */
  private readonly _nfcIso: NfcIso | null;

  /** The uid. */
  private readonly _uid: string;

  /** The data. */
  private readonly _data: string;

  /**
   * Instantiates a new Nfc.
   *
   * @param uid the uid
   * @param data the data
   * @param id the id
   * @param unitId the unit id
   * @param nfcIso the nfc iso
   * @param created the created date
   * @param updated the updated date
   * @param enabled the enabled flag
   */
  constructor(
      uid: string,
      data: string,
  id?: string,
      unitId?: string,
      nfcIso?: NfcIso | null,
      created?: Date,
      updated?: Date,
      enabled?: boolean) {

    // parent
    super(id, created, updated, enabled);

    // uid
    StringValidator.the(Noun.ARGUMENT, "uid", uid).minimumLength(Nfc.MINIMUM_UID_LENGTH).maximumLength(Nfc.MAXIMUM_UID_LENGTH).validate();
    this._uid = uid;

    // data
    StringValidator.the(Noun.ARGUMENT, "data", data).minimumLength(Nfc.MINIMUM_DATA_LENGTH).maximumLength(Nfc.MAXIMUM_DATA_LENGTH).validate();
    this._data = data;

    // unit id
    if (unitId !== undefined) {
      UuidValidator.the(Noun.ARGUMENTS, "unitId", unitId).allowNull().validate();
      this._unitId = unitId;
    } else {
      this._unitId = null;
    }

    // nfc iso
    this._nfcIso = nfcIso !== undefined ? nfcIso : null;
  }

  /**
   * Gets the uid.
   *
   * @return the uid
   */
  public get uid(): string {
    return this._uid;
  }

  /**
   * Gets the data.
   *
   * @return the data
   */
  public get data():string {
    return this._data;
  }

  /**
   * Gets the unit id.
   *
   * @return the unit id
   */
  public get unitId(): string | null {
    return this._unitId;
  }

  /**
   * Sets the unit id.
   *
   * @param unitId the unit id
   */
  public set unitId(unitId: string | null) {
    UuidValidator.the(Noun.ARGUMENTS, "unitId", unitId).allowNull().validate();
    this._unitId = unitId;
  }
  /**
   * Gets the iso.
   *
   * @return the iso
   */
  public get nfcIso(): NfcIso | null {
    return this._nfcIso;
  }
}
