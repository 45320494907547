import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PollingService } from './polling.service';
import { PingService } from './ping.service';
import { UtilsLocalstorageModule } from '@io-provetech/utils/localstorage-service';

@NgModule({
  imports: [CommonModule, UtilsLocalstorageModule],
  providers: [PollingService, PingService]
})
export class UtilsPollingModule {}
