import { Component, Input } from '@angular/core';

import * as icons from '../../assets/svg-icons';

@Component({
  selector: 'io-provetech-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  /** Icon name. */
  @Input() public icon!: string;

  /** Icons. */
  public icons = icons;
}
