<form [formGroup]="form" class="w-full flex flex-col gap-y-4">
  <ng-container *ngFor="let field of formFields">
    <io-provetech-input
      [type]="'text'"
      [label]="field.label ? field.label : field.id"
      [formControlName]="field.id"
      class="text-left"
    ></io-provetech-input>
  </ng-container>

  <div class="mt-2 flex justify-between gap-x-4">
    <io-provetech-button
      class="flex-1"
      (onClick)="close()"
      label="Cancel"
    ></io-provetech-button>

    <io-provetech-button
      class="flex-1"
      color="primary"
      (onClick)="submit()"
      [label]="config.submitButtonText ?? 'Submit'"
      [disabled]="!hasChanged"
    ></io-provetech-button>
  </div>
</form>

