import { Observable } from 'rxjs';

import { Manufacturer } from '@io-provetech/models';
import { Page } from '@nuov.io/spring/build/esm/src/Page';

import { AbstractHttpService } from '../http.service';
import {
  HeaderLike,
  HttpDeleteItem,
  HttpGetCollection,
  HttpGetItem,
  HttpPatchItem,
  HttpPostItem,
  IRequestOptions,
} from '../http.constants';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

/**
 * The class ManufacturerHttpService.
 */
@Injectable()
export class ManufacturerHttpService
  extends AbstractHttpService<Manufacturer>
  implements
    HttpDeleteItem<Manufacturer>,
    HttpGetCollection<Manufacturer>,
    HttpGetItem<Manufacturer>,
    HttpPatchItem<Manufacturer>,
    HttpPostItem<Manufacturer>
{
  protected getCollectionPath(): string {
    return '/manufacturers';
  }

  public override getCollection(params?: Params, filterParams?: HttpParams): Observable<Page<Manufacturer>> {
    return super.getCollection(params, filterParams);
  }

  public override getItem(id: string): Observable<Manufacturer> {
    return super.getItem(id);
  }

  public override postItem(
    model: Manufacturer,
    options?: IRequestOptions
  ): Observable<Manufacturer> {
    return super.postItem(model, options);
  }

  public override patchItem(
    id: string,
    model: Manufacturer
  ): Observable<Manufacturer> {
    return super.patchItem(id, model);
  }

  public override deleteItem(id: string): Observable<any> {
    return super.deleteItem(id);
  }
}
