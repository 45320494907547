import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Custom input component that implement ControlValueAccessor interface
 */
@Component({
  selector: 'io-provetech-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputComponent,
    multi: true,
  }],
})
export class InputComponent implements ControlValueAccessor {
  /** Input value. */
  value: any;

  /** The flag for input disabled state. */
  @Input() disabled = false;

  /** The label. */
  @Input() label = '';

  /** The placeholder. */
  @Input() placeholder = '';

  /** Input type. */
  @Input() type: 'text' | 'password' | 'number' | 'tel' | 'email' = 'text';

  /** The flag for input readonly state. */
  @Input() readonly = false;

  /** Max length indicator. */
  @Input() maxLength!: number;

  /** The flag for input isFocused state. */
  isFocused = false;

  private onChangeCallback: (value: string) => void = () => {};
  private onTouchedCallback: (value: string) => void = () => {};

  onChange(event: any): void {
    this.onChangeCallback(event.target?.value ?? '');
  }

  onBlur(event: any): void {
    this.isFocused = false;
    this.onTouchedCallback(event.target?.value ?? '');
  }

  onFocus(event: any): void {
    this.isFocused = true;
  }

  toggleEnable(): void {
    this.disabled = !this.disabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
