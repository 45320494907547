import { Injectable, Injector, Inject } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';

import { ToastComponent } from './toast.component';
import { TOAST_CONFIG_TOKEN, ToastConfig, ToastType, defaultToastConfig } from './toast-config';
import { ToastRef } from './toast-ref';
import { SuccessToastComponent } from './success-toast/success-toast.component';
import { InfoToastComponent } from './info-toast/info-toast.component';
import { ErrorToastComponent } from './error-toast/error-toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  /**
   * The constructor.
   * 
   * @param overlay overlay reference
   * @param injector injector
   */
  constructor(private overlay: Overlay, private injector: Injector) {}

  /**
   * Show message method.
   * 
   * @param text Toast inner text.
   * @param type Toast type.
   * @returns ToastRef
   */
  private showMessage<T>(component: ComponentType<T>, text: string, type: ToastType): ToastRef {
    const positionStrategy = this.overlay
      .position()
      .global()
    
    const overlayRef = this.overlay.create({positionStrategy});

    const toastRef = new ToastRef(overlayRef);
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: ToastRef, useValue: toastRef },
        { provide: TOAST_CONFIG_TOKEN, useValue: { ...defaultToastConfig, text, type } }
      ]
    });

    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);

    return toastRef;
  }

  /**
   * Show success toast.
   * 
   * @param text Toast inner text.
   * @returns ToastRef
   */
  public showSuccess(text: string): ToastRef {
    return this.showMessage(SuccessToastComponent, text, 'success');
  }

  /**
   * Show warning toast.
   * 
   * @param text Toast inner text.
   * @returns ToastRef
   */
  public showWarning(text: string): ToastRef {
    return this.showMessage(ErrorToastComponent, text, 'warning');
  }

  /**
   * Show info toast.
   * 
   * @param text Toast inner text.
   * @returns ToastRef
   */
  public showInfo(text: string): ToastRef {
    return this.showMessage(InfoToastComponent, text, 'info');
  }
}