import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Page } from '@nuov.io/spring/build/esm/src/Page';
import { NumberValidator, Noun}  from '@nuov.io/validator';
import { Key, Model } from "@nuov.io/model";

import { AbstractFilter, FilterSubject } from "@io-provetech/utils/http-service";

@Component({
  selector: 'io-provetech-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent<T extends Model> extends AbstractFilter<T> implements OnChanges {
  /** The filter Subject. */
  @Input() filterSubject!: FilterSubject<T>;

  /** The page. */
  @Input() page: Page<T> = {} as Page<T>;

  /** The [page] size. */
  @Input() size = 20;

  /** The page captions. */
  pageLabels!: number[];

  /** The separator. */
  private readonly separator = -1;

  ngOnInit(): void {
    this.setFilterObserver(this.filterSubject);
    this.append('size', this.size);
    this.changeHandler();
  }

  /** On change handler for the page. */
  ngOnChanges(changes: SimpleChanges): void {

    // examples

    // any selected
    // [<] [1] [>]
    // [<] [1] [2] [>]
    // [<] [1] [2] [3] [>]
    // [<] [1] [2] [3] [4] [>]
    // [<] [1] [2] [3] [4] [5] [>]
    // [<] [1] [2] [3] [4] [5] [>]
    // [<] [1] [2] [3] [4] [5] [6] [>]
    // [<] [1] [2] [3] [4] [5] [6] [7] [>]

    // asterix selected
    // [<] [1*] [2] [3] [...] [8] [>]
    // [<] [1] [2*] [3] [4] [...] [8] [>]
    // [<] [1] [2] [3*] [4] [5] [...] [8] [>]
    // [<] [1] [...] [4] [5] [6*] [7] [8] [>]
    // [<] [1] [...] [5] [6] [7*] [8] [>]
    // [<] [1] [...] [6] [7] [8*] [>]
    // [<] [1] [...] [3] [4] [5*] [6] [7] [8] [>]
    // [<] [1] [...] [3] [4] [5*] [6] [7] [...] [9] [>]
    // [<] [1] [...] [18] [19] [20*] [21] [22] [...] [40] [>]

    // page 1
    this.pageLabels = [1];
    // total pages > 1
    if (this.page && this.page?.totalPages > 1) {

      // separator
      if (Number(this.page.number) - 2 > 2) {
        this.pageLabels.push(this.separator);
      }

      // loop:
      // from: 2 pages before current page
      // to: 2 pages after the current page
      for (let i = (Number(this.page.number) - 2); i <= (Number(this.page.number) + 2); i++) {

        if (i >= 2 && i < this.page.totalPages) {

          // add the page
          this.pageLabels.push((i));

          // stop at total pages
          if (i === (Number(this.page.totalPages) - 1)) {
            break;
          }
        }
      }

      // separator
      if ((Number(this.page.number) + 2) < (Number(this.page.totalPages) - 2)) {
        this.pageLabels.push(this.separator);
      }
      // total pages
      this.pageLabels.push(Number(this.page.totalPages));
    }
  }

  getCss(page: number): string {
    if (!this.page) return '';
    const commonCss = "relative items-center px-4 py-2 text-sm font-semibold ";

    // current
    if (page === Number(this.page.number)) {
      return commonCss + "z-10 inline-flex bg-indigo-600 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";
    }

    // page -/+ 1
    else if (this.page.first || this.page.last || (page === Number(this.page.number) - 1) || (page === Number(this.page.number) + 1)) {
      return commonCss + "inline-flex text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0";
    }

    // hides
    else {
      return commonCss + "hidden text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex";
    }
  }

  goToNextPage() {
    NumberValidator.the(Noun.PROPERTY, 'page.number', this.page.pageable.pageNumber).minimum(0).maximum(this.page.totalPages - 1).validate()
    this.goToPage(Number(this.page.pageable.pageNumber) + 1);
  }

  goToPage(page: number) {
    // validate
    NumberValidator.the(Noun.ARGUMENT, 'page', page).minimum(0).maximum(this.page.totalPages - 1).validate()
    NumberValidator.the(Noun.PROPERTY, 'size', this.size).minimum(1).maximum(this.page.totalPages + 1).validate()

    // page
    this.delete(Key.PAGE);
    this.append(Key.PAGE, Number(page));

    // size
    if (!this.httpParams.has(Key.SIZE)) {
      this.append(Key.SIZE, Number(this.size));
    }

    // change handler
    this.changeHandler();
  }

  goToPreviousPage() {
    NumberValidator.the(Noun.PROPERTY, 'page.number', this.page.number).minimum(1).validate()
    this.goToPage(Number(this.page.number) - 1);
  }

  isSeparator(page: number): boolean {
    return page === this.separator;
  }

  isCurrent(page: number): boolean {
    return page === this.page?.number;
  }
}
