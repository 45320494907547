import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map } from 'rxjs';

import { INavTab, IMenuItem, User } from '@io-provetech/models';

@Component({
  selector: 'io-provetech-nav',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class ShellComponent implements OnInit {
  /** Profile menu button reference.  */
  @ViewChild('menuButton') private _menuBtnRef!: ElementRef;

  /** Nav tabs input. */
  @Input() navTabs!: INavTab[];

  /** Menu items input. */
  @Input() menuItems!: IMenuItem[];

  /** Logo image src. */
  @Input() logoImgSrc!: string;

  /** Profile image scr. */
  @Input() profileImgSrc!: string;

  /** User info. */
  @Input() user!: User;

  /** Checks if profile menu is opened. */
  private _isMenuShown = false;

  /** Checks if mobile burger menu is expanded */
  private _isMobileMenuExtended = false;

  /** isMenuShown var getter */
  get isMenuShown(): boolean {
    return this._isMenuShown;
  }

  /** isMobileMenuExtended var getter */
  get isMobileMenuExtended(): boolean {
    return this._isMobileMenuExtended;
  }

  /** Page title. */
  title$!: Observable<string>;

  /** Default profile image for cases where user don't have one. */
  defaultProfileImg =
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';

  constructor(private router: Router) {}

  ngOnInit() {
    // finding title for current route
    this.title$ = this.router.events.pipe(
      map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot['title']) {
          routeTitle = route!.snapshot['title'];
        }
        return routeTitle;
      })
    );
  }

  /** Method handler for clickOutside directive */
  outsideClickHandler(target: EventTarget) {
    if (this.isMenuShown && !this._menuBtnRef.nativeElement.contains(target)) {
      this.toggleMenu();
    }
  }

  /** Switch method for isMenuShown var */
  toggleMenu(): void {
    this._isMenuShown = !this._isMenuShown;
  }

  /** Switch method for isMobileMenuExtended var */
  toggleMobileMenu(): void {
    this._isMobileMenuExtended = !this._isMobileMenuExtended;
  }
}
