import { Observable } from 'rxjs';

import { Production } from '@io-provetech/models';
import { Page } from '@nuov.io/spring/build/esm/src/Page';

import { AbstractHttpService } from '../http.service';
import {
  HttpDeleteItem,
  HttpGetCollection,
  HttpGetItem,
  HttpPatchItem,
  HttpPostItem,
  IRequestOptions,
  MANUFACTURER_ID,
  PRODUCT_ID,
} from '../http.constants';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';


/**
 * The class ManufacturerHttpService.
 */
@Injectable()
export class ProductionHttpService
  extends AbstractHttpService<Production>
  implements
    HttpDeleteItem<Production>,
    HttpGetCollection<Production>,
    HttpGetItem<Production>,
    HttpPatchItem<Production>,
    HttpPostItem<Production>
{
  getCollectionPath(params: Params): string {
    return `/manufacturers/${params[MANUFACTURER_ID]}/products/${params[PRODUCT_ID]}/productions`;
  }

  public override getCollection(params: Params, filterParams?: HttpParams): Observable<Page<Production>> {
    return super.getCollection(params, filterParams);
  }

  public override getItem(id: string, params: Params): Observable<Production> {
    return super.getItem(id, params);
  }

  public override postItem(
    model: Production,
    params: Params,
    options?: IRequestOptions
  ): Observable<Production> {
    return super.postItem(model, params, options);
  }

  public override patchItem(
    id: string,
    model: Production,
    params: Params
  ): Observable<Production> {
    return super.patchItem(id, model, params);
  }

  public override deleteItem(id: string, params: Params): Observable<any> {
    return super.deleteItem(id, params);
  }

  public addImage(id: string, params: Params, image: File) {
    const formData = new FormData();
    formData.append('image', image, image.name);

    return this.http.post(
      `${this.apiUrl}${this.getCollectionPath(params)}/${id}/production-images`,
      formData
    );
  }

  public removeImage(id: string, params: Params, imageId: string) {
    return this.http.delete(
      `${this.apiUrl}${this.getCollectionPath(params)}/${id}/production-images/${imageId}`
    );
  }
}
