import { Component, Inject, InjectionToken, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { DynamicDialogComponent, IDynamicFormModel } from '../dialog';
import { BehaviorSubject, Subject, catchError, debounceTime, filter, of, takeUntil, throwError } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

export const DIALOG_FORM_TOKEN = new InjectionToken<DynamicDialogComponent>(
  'dialog_form_token'
);

@Component({
  selector: 'io-provetech-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnDestroy {

  protected readonly unsubscriber$ = new Subject<void>();

  /** The form. */
  public form: FormGroup;

  /** Form fields for displaying */
  public formFields: IDynamicFormModel[] = [];

  public hasChanged: boolean = false;

  private oldFormValue: { [key: string]: any } = {};

  constructor(
    @Inject(DIALOG_FORM_TOKEN) public config: DynamicDialogComponent,
    private fb: FormBuilder
  ) {
    // generate dynamic form based on config
    this.form = this.fb.group({});
    if (this.config.dynamicFormModel) {
      for (const fieldModel of this.config.dynamicFormModel) {
        this.form.addControl(
          fieldModel.id,
          new FormControl(fieldModel.value, fieldModel.validators)
        );
        this.formFields.push(fieldModel);
        this.oldFormValue[fieldModel.id] = fieldModel.value;
      }
    }

    this.detectFormChange.bind(this)();
  }

  public detectFormChange(): void {
    this.form.valueChanges
    .pipe(
      takeUntil(this.unsubscriber$),
      debounceTime(300),
      filter(() => Boolean(Object.keys(this.oldFormValue).length))
    )
    .subscribe((value) => {
      this.hasChanged = this.checkIfChanged(this.oldFormValue, value);
    });
  }

  private checkIfChanged(
    oldFormValue: { [key: string]: any },
    newFormValue: { [key: string]: any }
  ): boolean {
    if (oldFormValue === undefined) return true;

    for (let key of Object.keys(newFormValue)) {
      if (newFormValue[key] !== oldFormValue[key]) {
        return true;
      }
    }

    return false;
  }

  submit(): void {
    if (!this.form.valid) return;

    if (!this.hasChanged) return;

    if (this.config.action) {
      let action$;
      const data = this.config.data ? this.config.data(this.form.value) : this.form.value;
      console.log(data, 'data')
      if (this.config.dialogType === 'ADD') {
        action$ = this.config.action(data, this.config.params);
      } else if (this.config.dialogType === 'EDIT') {
        action$ = this.config.action(
          this.config.params?.['id'],
          data,
          this.config.params
        );
      }
      (action$ ? action$ : of(false))
        .pipe(
          takeUntil(this.unsubscriber$),
          catchError((error: HttpErrorResponse) => {
            this.config.dialogRef.close();

            return throwError(() => error);
          })
        )
        .subscribe((response: HttpResponse<any>) => {
          // this.toastService.showSuccess('Success');
          this.config.dialogRef.close(response);
        });
    }

    // this.config.dialogRef.close(true);
  }

  close() {
    this.config.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.unsubscribe();
  }
}
