<div class="min-h-full">
  <nav class="border-b border-gray-200 bg-white">
    <div class="mx-auto max-w-mac-13 px-4 sm:px-6 lg:px-12">
      <div class="flex h-16 justify-between">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center">
            <io-provetech-logo
              class="block lg:hidden"
            ></io-provetech-logo>
            <io-provetech-logo
              class="hidden lg:block"
            ></io-provetech-logo>
          </div>
          <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
            <a
              *ngFor="let tab of navTabs"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
              [routerLink]="[tab.route]"
              routerLinkActive="!border-indigo-500 !text-gray-900"
              >{{ tab.name }}
            </a>
          </div>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <button
            type="button"
            class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span class="sr-only">View notifications</span>
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
              />
            </svg>
          </button>

          <!-- Profile dropdown -->
          <div class="relative ml-3">
            <div>
              <button
                (click)="toggleMenu()"
                #menuButton
                type="button"
                class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span class="sr-only">Open user menu</span>
                <img
                  *ngIf="profileImgSrc"
                  class="h-8 w-8 rounded-full"
                  [src]="profileImgSrc"
                  alt=""
                />

                <img
                  *ngIf="!profileImgSrc"
                  class="h-8 w-8 rounded-full"
                  [src]="defaultProfileImg"
                  alt=""
                />
              </button>
            </div>

            <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-200"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div
              @opacityScale
              [ngClass]="{ hidden: !isMenuShown }"
              class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
              (ioProvetechClickOutside)="outsideClickHandler($event)"
            >
              <!-- Active: "bg-gray-100", Not Active: "" -->
              <a
                *ngFor="let item of menuItems"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-800"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-0"
                (click)="item.action()"
                >{{ item.name }}
              </a>
            </div>
          </div>
        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <button
            (click)="toggleMobileMenu()"
            type="button"
            class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <!-- Menu open: "hidden", Menu closed: "block" -->
            <svg
              *ngIf="!isMobileMenuExtended"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
            <!-- Menu open: "block", Menu closed: "hidden" -->
            <svg
              *ngIf="isMobileMenuExtended"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div *ngIf="isMobileMenuExtended" class="sm:hidden" id="mobile-menu">
      <div class="space-y-1 pt-2 pb-3">
        <a
          *ngFor="let tab of navTabs"
          [routerLink]="tab.route"
          routerLinkActive="!border-indigo-500 !bg-indigo-50 !text-indigo-700"
          class="text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800 block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
          >{{ tab.name }}
        </a>
      </div>
      <div class="border-t border-gray-200 pt-4 pb-3">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <img
              *ngIf="profileImgSrc"
              class="h-10 w-10 rounded-full"
              [src]="profileImgSrc"
              alt=""
            />

            <img
              *ngIf="!profileImgSrc"
              class="h-10 w-10 rounded-full"
              [src]="defaultProfileImg"
              alt=""
            />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">
              {{ user ? user.firstName : '' }} {{ user ? user.lastName : '' }}
            </div>
            <div class="text-sm font-medium text-gray-500">
              {{ user ? user.email : '' }}
            </div>
          </div>
          <button
            type="button"
            class="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span class="sr-only">View notifications</span>
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
              />
            </svg>
          </button>
        </div>
        <div class="mt-3 space-y-1">
          <a
            *ngFor="let item of menuItems"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
            role="menuitem"
            tabindex="-1"
            id="user-menu-item-0"
            (click)="item.action()"
            >{{ item.name }}
          </a>
        </div>
      </div>
    </div>
  </nav>

  <div class="py-10">
    <header>
      <div class="mx-auto max-w-mac-13 px-4 sm:px-6 lg:px-8">
        <h1
          class="text-3xl font-bold leading-tight tracking-tight text-gray-900"
        >
          {{ title$ | async }}
        </h1>
      </div>
    </header>
    <main>
      <div class="mx-auto max-w-mac-13 px-4 md:px-6 lg:px-8">
        <!-- Your content -->
        <ng-content></ng-content>
      </div>
    </main>
  </div>
</div>
