import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { RoutesConstants } from 'libs/models/src/lib/routes.constants';
import { AuthService } from 'libs/auth/src/lib/auth.service'
import { ToastService } from 'libs/shared-ui/src/lib/toast'

// See:
// https://stackoverflow.com/questions/46019771/catching-errors-in-angular-httpclient

/** HTTP errors interceptor */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toasterService: ToastService
  ) {}

  /**
   * 
   * @param request 
   * @param next 
   * @returns updated request
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {

        // 401 unauthorization error - try to update access token with refresh token
        if (httpErrorResponse.status === 401) {
          return this.handle401Error(request, next);
        }

        // 304 error
        if (httpErrorResponse.status === 304) {
          this.toasterService.showInfo(httpErrorResponse.message);
          return next.handle(request);
        }

        // client-side or network error
        const statusCodes = [400, 403, 404, 423, 503];
        if (statusCodes.includes(httpErrorResponse.status)) {
          this.toasterService.showWarning(httpErrorResponse.message);
          // this.router.navigate([
          //   RoutesConstants.common.error.defaultError.route + '/' + httpErrorResponse.status],
          //   { state: { errorCode: httpErrorResponse.status, errorMessage: httpErrorResponse.message } } 
          // );
        } else {
          this.router.navigateByUrl(
            '/' + RoutesConstants.common.error.defaultError.route
          );
        }

        return throwError(() => httpErrorResponse);
      })
    );
  }

  /** 
   * @param request 
   * @param next 
   * @returns updated request with refreshed token
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshToken().pipe(
      switchMap((token) => {
        let httpHeaders = new HttpHeaders()
          .append('authorization', `Bearer ${token}`);
        const cloneReq = request.clone({
          headers: httpHeaders
        });

        return next.handle(cloneReq);
      }),
      catchError((error) => throwError(() => error))
    );

  }
}
