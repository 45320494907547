import {AbstractModelUpdated} from '@nuov.io/model';
import {Unit} from "./Unit";
import {UuidValidator} from "@nuov.io/validator";
import {Noun} from "@nuov.io/sentence";

/** The Class UserUnit. */
export class UserUnit extends AbstractModelUpdated {

  /** The user id. */
  private readonly _userId: string;

  /** The unit. */
  private readonly _unit: Unit;

  /**
   * Instantiates a new Unit.
   *
   * @param userId the user id
   * @param unit the unit
   * @param id the id
   * @param created the created date
   * @param updated the updated date
   * @param enabled the enabled flag
   */
  constructor(
      userId: string,
      unit: Unit,
  id?: string,
      created?: Date,
      updated?: Date,
      enabled?: boolean) {
    super(id, created, updated, enabled);

    // user id
    UuidValidator.the(Noun.ARGUMENT, "userId", userId).validate();
    this._userId = userId;

    // unit
    this._unit = unit;
  }

  /**
   * Gets the user id.
   *
   * @return the user id
   */
  public get userId(): string {
    return this._userId;
  }

  /**
   * Gets the unit.
   *
   * @return the unit
   */
  public get unit(): Unit {
    return this._unit;
  }
}
